import React from 'react';

const BottomBar = ({
  game,
  keyPlayerIndex,
  palceBid,
  restartGame,
  gotoHome,
  myTeam,
  bidderTeamPoint,
  opponentTeamPoint,
  otherTeamPoint,
}) => {
  return (
    <div className='cg_bottom_bar'>
      {!game.gameStarted ? (
        <div className="bottom_bar_content text_white">
          <h1 className='secondary_text'>Waiting for players to join...</h1>
        </div>
      ) : null}
      {game.gameStarted && !game.bidCompleted ? (
        <div className='cg_bid'>
          <div className='bid_wrapper'>
            <h5 className='primary_text'>Last Bid: <span className='text_yellow'>{game.highBidPoints}  </span>
              {game.playerBidPoints[keyPlayerIndex] > 0 ? (
                <>
                  | My Last Bid: <span className='text_yellow'>{game.playerBidPoints[keyPlayerIndex]}</span>
                </>
              ) : null}
              {game.playerBidPoints[keyPlayerIndex] === -1 ? (
                <>
                  | My Last Bid: <span className='text_yellow'>PASS</span>
                </>
              ) : null}
            </h5>
          </div>
          {game.nextPlayerIndex === keyPlayerIndex ? (
            <>
              <div className='bid_btn_wrapper'>
                <button className='btn-primary btn-add-bid' onClick={palceBid} value={game.highBidPoints - 10}>
                  -
                </button>
                <input className='bg_dark text_white' value={game.highBidPoints + 10} readOnly />
                <button className='btn-primary btn-add-bid' onClick={palceBid} value={game.highBidPoints + 10}>
                  +
                </button>
              </div>
              <div className='pass_btn_wrapper'>
                <button className='btn btn-primary' onClick={palceBid} value={game.highBidPoints + 10}>Bid</button>
                <button className='btn btn-primary' onClick={palceBid} value={-1}>Pass</button>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {game.bidCompleted && (!game.powerSuit || game.powerSuit === '') ? (
        <div className='cg_powersuit'>
          <div className='cg_powersuit_left'>
            <div>
              <h5 className='primary_text'>Bid Winner <span className='text_yellow'>{game.players[game.bidWinnerIndex].username}</span> </h5>
            </div>
            <div>
              <h5 className='primary_text'>Winner Bid Points </h5>
              <button className='btn btn-primary' disabled>{game.playerBidPoints[game.bidWinnerIndex]}</button>
            </div>
            <div>
              <h5 className='primary_text'>Total Cards Point </h5>
              <button className='btn btn-primary' disabled>{game.totalCardsPoints}</button>
            </div>
            <div>
              <h5 className='primary_text'>Your Cards Point </h5>
              <button className='btn btn-primary' disabled>{game.allPlayerCardPoints[keyPlayerIndex]}</button>
            </div>
          </div>
        </div>
      ) : null}
      {game.bidCompleted && game.powerSuit && game.powerSuit !== '' ? (
        <div className='cg_powersuit'>
          <div className='cg_powersuit_left'>
            <div>
              <h5 className='primary_text'>Bid Winner  <span className='text_yellow'>{game.players[game.bidWinnerIndex].mqId}</span> </h5>
            </div>
            <div>
              <h5 className='primary_text'>Power Suit</h5>
              <div className={`cg_card cg_half_card cg_card_${game.powerSuit}`}>
                <div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
              </div>
            </div>
            <div>
              <h5 className='primary_text'>Partners</h5>
              {
                game.partnersCard.map(function (partnerCard, index) {
                  return (
                    <div className={`cg_card cg_half_card cg_card_${partnerCard.cardSuit}`} value={partnerCard.cardSeq} key={index}>
                      <div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
                      <div className='cg_deck_order'>{partnerCard.order}</div>
                    </div>
                  )
                })
              }
            </div>
            <div className="cg_total_score">
              <h5 className="primary_text">Points: <span className='text_yellow'>
                  {myTeam === "BIDDER" ? (
                    <>
                      {bidderTeamPoint} / {game.highBidPoints}
                    </>
                  ) : null}
                  {myTeam === "OPPONENT" ? (
                    <>
                      {opponentTeamPoint} / {game.totalCardsPoints - game.highBidPoints + 5}
                    </>
                  ) : null}
                  {myTeam === "" ? (
                    <>
                      {otherTeamPoint} / ?
                    </>
                  ) : null}
                </span>
                </h5>
            </div>
          </div>
          {game.gameEnded ? (
            <div className="ms-auto cg_right_btns">
              <div className="cg_powersuit_right">
                <button className='btn btn-primary me-3' onClick={restartGame}>Restart</button>
                <button className='btn btn-primary me-3' onClick={gotoHome}>Home</button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default BottomBar;
