import React, { useEffect } from "react";
import $ from "jquery";
import "./Tournament.css";

import TournamentCard from '../UI/TournamentCard';

const Tournament = () => {
    useEffect(() => {
        // Show the first tab content
        $("#tab1").show();
        $("#footer_tab1").show();

        // Handle tab click event
        $(".cg_nav_item").click(function () {
            const tabId = $(this).data("tab");

            // Hide all tab content
            $(".cg_tab_content").hide();

            // Remove active class from all tabs
            $(".cg_nav_item").removeClass("active_nav");

            // Show the selected tab content
            $("#" + tabId).show();

            // Add active class to the selected tab
            $(this).addClass("active_nav");
        });
    }, []);

    return (
        <>
            <div className="cg_nav_container">
                <ul className="cg_nav">
                    <li className="cg_nav_item  active_nav" data-tab="tab1">Contest</li>
                    <li className="cg_nav_item " data-tab="tab2">My Contest</li>
                    <li className="cg_nav_item " data-tab="tab3">Completed</li>
                </ul>

                <div className="cg_tab_content" id="tab1">
                    <TournamentCard showStar={true} fastMessage={true} playerInfo={true} showButton={true} showProgress={true} EventTag="Hurry!!" EventInfo="2 Player per table - 1 deal to promoted" TournamentName="Freeroll 5K GTD" PrizeAmount="500" WinnerPrizeAmount="250" ContestSize="51/1000" />
                    <TournamentCard showStar={true} fastMessage={true} playerInfo={true} showButton={true} showProgress={true} EventTag="New" EventInfo="10 Minutes/Round Tournament" TournamentName="Welcome Cashloot" PrizeAmount="5,000" WinnerPrizeAmount="7" ContestSize="1/32" />
                    <TournamentCard showStar={true} fastMessage={true} playerInfo={true} showButton={true} showProgress={true} EventTag="Fast" EventInfo="15 Minutes Per Round" TournamentName="Wednesday Welcomr 5K GTD" PrizeAmount="3Lakhs" WinnerPrizeAmount="22" ContestSize="0/100" />
                    <TournamentCard showStar={true} fastMessage={true} playerInfo={true} showButton={true} showProgress={true} EventTag="Fast" EventInfo="15 Minutes Per Round" TournamentName="Wednesday Welcomr 5K GTD" PrizeAmount="3Lakhs" WinnerPrizeAmount="22" ContestSize="0/100" />
                </div>

                <div className="cg_tab_content" id="tab2">
                    <TournamentCard showStar={false} fastMessage={false} playerInfo={false} showButton={false} showProgress={false} EventTag="Hurry!!" EventInfo="2 Player per table - 1 deal to promoted" TournamentName="Freeroll 5K GTD" PrizeAmount="500" WinnerPrizeAmount="250" ContestSize="51/1000" />
                    <TournamentCard showStar={false} fastMessage={false} playerInfo={false} showButton={false} showProgress={false} EventTag="New" EventInfo="10 Minutes/Round Tournament" TournamentName="Welcome Cashloot" PrizeAmount="5,000" WinnerPrizeAmount="7" ContestSize="1/32" />
                    <TournamentCard showStar={false} fastMessage={false} playerInfo={false} showButton={false} showProgress={false} EventTag="Fast" EventInfo="15 Minutes Per Round" TournamentName="Wednesday Welcomr 5K GTD" PrizeAmount="3Lakhs" WinnerPrizeAmount="22" ContestSize="0/100" />
                </div>

                <div className="cg_tab_content" id="tab3">
                    <TournamentCard showStar={false} fastMessage={false} playerInfo={false} showButton={false} showProgress={false} EventTag="Hurry!!" EventInfo="2 Player per table - 1 deal to promoted" TournamentName="Freeroll 5K GTD" PrizeAmount="500" WinnerPrizeAmount="250" ContestSize="51/1000" />
                </div>
            </div>
        </>
    );
};

export default Tournament;
