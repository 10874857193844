import React, { useState } from "react";
import './GameHistoryCard.css';
import useSound from 'use-sound';
import Global from "../Global";
import { FaClipboardList } from 'react-icons/fa';
import ScoreModal from '../Modals/ScoreModal';
import { singleClickSoundFile } from "../../audio";


const GameHistoryCard = ({ TournamentName, Result, ghPlayersPoint }) => {

    const [scoreModalShow, setScoreModalShow] = useState(false);

    const [singleClickSound] = useSound(singleClickSoundFile, {
        volume: Global.sound ? Global.volume : 0,
    });
    
    const showScoreModal = () => {
        singleClickSound();
        // setScoreModalShow(true);
        alert('Button clicked');
    };

    const closeScoreModal = () => {
        setScoreModalShow(false);
    };

    return (
        <>
            <div className="gh_card">
                <div className="gh_card_header">
                    <h3>{TournamentName}</h3>

                    <div className="right_side">
                        <div className="nav_item">
                            {/* <button className="gh_card_btn" onClick={ showScoreModal }>Score</button> */}
                            <button className="nav_btn" onClick={ showScoreModal }> <FaClipboardList /> </button>
                        </div>
                    </div>
                </div>
                <div className="gh_card_body">
                    <h5 className="gh_card_title">Lorem, ipsum dolor.</h5>
                    <table className="gh_table">
                        <thead>
                            <tr>
                                <th>Result</th>
                                <th>Player Points</th>
                                <th>Bid Points</th>
                                <th>Team Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{Result}</td>
                                <td>{ghPlayersPoint}</td>
                                <td>225</td>
                                <td>110</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <ScoreModal
                showModal={scoreModalShow}
                closeModal={closeScoreModal}
            />

        </>
    )
}

export default GameHistoryCard;