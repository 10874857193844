import React from "react";

import GameTable from '../Components/pages/GameTable';
import Tournament from '../Components/pages/Tournament';
import Home from '../Components/pages/Home';
import Playwithfrnd from "../Components/pages/Playwithfrnd";
import CreateTable from "../Components/pages/Createtable";
import JoinTable from "../Components/pages/Jointable";

import GameHistoryList from "../Components/pages/GameHistoryList";

// Authentication related pages
// import Login from "../Components/page/Auth/Login";


// ======================= NON AUTH ROUTE ================================ //
export  const nonAuthRoutes = [
  // // Authencation page
  //  { path: "/login", component: <Login/> },
  { path: "/", component: <Home/> },
  { path: "/home", component: <Home/> },
  { path: "/playwithfrnd", component: <Playwithfrnd/> },
  { path: "/createtable", component: <CreateTable/> },
  { path: "/jointable", component: <JoinTable/> },
  { path: "/gameTable", component: <GameTable/> },
  { path: "/tournament", component: <Tournament/> },
  { path: "/gameHistoryList", component: <GameHistoryList/> },
  
];

