import React from "react";

import PlayerCard from './PlayerCard';

const WinnerCard = ({game, completedHand, keyPlayerIndex}) => {
    return (
            <div className="cg_winner_card">

                {
                    completedHand && completedHand.cards.map((item, index) =>{
                        let pIndex = completedHand.startPlayerIndex + index;
                        if(pIndex >= completedHand.cards.length){
                            pIndex -= completedHand.cards.length;
                        }
                        let cardSuit = item.split('_')[0];
                        let cardSequance = item.split('_')[1];
                        return <PlayerCard key={pIndex} suit={cardSuit} seq={cardSequance} pIndex={pIndex} />
                    })
                }
            </div>
    );
}

export default WinnerCard;