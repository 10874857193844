import React, { useContext, useEffect, useRef, useState } from "react";
import useSound from 'use-sound';
import "./Home.css";
import "./Createtable.css";
import Topbar from "./partial/Topbar";
import { AiFillCopy } from "react-icons/ai";
import { FaShare } from "react-icons/fa";
import { singleClickSoundFile } from "../../audio";
import Global from "../Global";
import { SocketContext } from "../../context/socket";


const Createtable = () => {

	const queryString = require('query-string');
	const queryParams = queryString.parse(window.location.search);

    const socket = useContext(SocketContext);
    const roomCodeRef = useRef(null);

    const [action, setAction] = useState('');
    const [gameCode, setGameCode] = useState(0);
    const [game, setGame] = useState(null);
    const [user, setUser] = useState(Global.getUser());

    const [ClickBtnSound] = useSound(singleClickSoundFile, {
        volume: Global.sound ? Global.volume : 0,
    });

    
	useEffect(() => {        

		setAction(queryParams.action);
		setGameCode(queryParams.gameCode);
		
		let connectData = {
			userId: user._id,
			action: queryParams.action,
			gameCode: queryParams.gameCode,
			time: new Date()
		};


		const gameTableSocketIdInput = document.getElementById('gameTableSocketId');

		gameTableSocketIdInput.value = socket.id;
		socket.emit('connect_server', connectData, (response) => {
			console.log(response.status); // ok
		});
		socket.on('connect', function () {
			// When user reconnect socket and if socket id updated
			// Then emit connect_server event which help to rejoin all relavent chanel
			if (gameTableSocketIdInput.value !== socket.id) {
				gameTableSocketIdInput.value = socket.id;
				socket.emit('connect_server', connectData, (response) => {
					console.log(response.status);
				});
			}
		});
        socket.on('data', data => {
			if (data.type === 'game_joined' || data.type === 'game_data') {
				setGame(data.game);
			}
		});

		socket.on('user_' + user._id, data => {
			console.log("Socket user_============", new Date());
			if (data.type === 'game_data') {
				setGame(data.game);
			} else if (data.type === 'tournament_data') {
				
			}
		});

		socket.on('error', data => {
			//setLoded(false)
			//setError(data.message);
			console.log("Socket error============", data)
		});

		return () => {
			socket.off('data');
			socket.off('user_' + user._id);
			socket.off('error');
		};

	}, []);

    const handleCodecopy = () => {
        ClickBtnSound();
        const roomCodeElement = roomCodeRef.current;
        if (roomCodeElement && navigator.clipboard) {
            const roomCode = roomCodeElement.textContent;
            navigator.clipboard.writeText(roomCode)
        }
    };

    const handleShare = () => {
        ClickBtnSound();
    };
   
    return (
        <>
            <Topbar showSelectAvatar={true} showGameHistory={false} showScoreModal={true}/>
            <input type='hidden' id='gameTableSocketId' />
            {game ? (<>
            <div className="create_join_table">
                <h1 className="text_white title_text">Awesome!</h1>
                <img src="/image/create_table_image.svg" alt="Create Table" />
                <h4 className="text_white info_text">Your private table no</h4>
                <div className="room_code_wrapper">
                    <h6 className="text_yellow room_code" ref={roomCodeRef}>game.gameCode</h6>
                    <button className="btn_success text_yellow" onClick={handleCodecopy}><AiFillCopy /></button>
                    <button className="btn_success text_yellow" onClick={handleShare}><FaShare /></button>
                </div>
            </div>
            <div className='cg_bottom_bar'>
                <div className="bottom_bar_content text_white">
                    <h1>Tell others to use this code to join the room.</h1>
                </div>
            </div>
            </>) : null}
        </>
    );
};

export default Createtable;