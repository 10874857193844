import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
import Modal from 'react-modal';
import { singleClickSoundFile } from "../../../audio";
import { FaUserAlt } from "react-icons/fa";
import Global from "../../Global";
import "../Home.css";

function AuthModal({ defaultModal }) {

    const MODAL_ENUM = {
        NONE: "",
        LOGIN_CHOICE: "Login Choice",
        GUEST_LOGIN: "Guest Login",
        LOGIN: "Login",
        REGISTER: "Register",
        FORGOT_PASS: "Forgot Password",
        RESET_PASS: "Reset Password"
    };

    const [singleClickSound] = useSound(singleClickSoundFile, {
        volume: Global.getSound() ? Global.getVolume() : 0,
    });

    const [showModal, setShowModal] = useState(defaultModal ? defaultModal : (Global.getUser() ? MODAL_ENUM.NONE : MODAL_ENUM.LOGIN_CHOICE));

    const [guestInputs, setGuestInputs] = useState({
        username: ''
    });
    const [loginInputs, setLoginInputs] = useState({
        username: '',
        password: ''
    });
    const [registerInputs, setRegisterInputs] = useState({
        email: '',
        username: '',
        password: ''
    });
    const [guestError, setGuestError] = useState({
        username: '',
        generic: ''
    });
    const [loginError, setLoginError] = useState({
        username: '',
        password: '',
        generic: ''
    });

    const [registerError, setRegisterError] = useState({
        email: '',
        username: '',
        password: '',
        generic: ''
    });

    
    useEffect(() => {

    }, []);

    const handleShowModalEvent = (e) => {
        singleClickSound();
        let modalName = e.target.getAttribute('show-modal');
        setShowModal(modalName);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        // Need to write logic
    }

    const handleGuestInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setGuestInputs(values => ({...values, [name]: value}));
        if(name === "username"){
            let error = validateUsername(value);
            setGuestError(values => ({...values, "generic": "", [name]: error}));
        }
    }
    const handleLoginInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setLoginInputs(values => ({...values, [name]: value}));
        if(name === "username"){
            let error = validateUsername(value);
            setLoginError(values => ({...values, "generic": "", [name]: error}));
        }
        if(name === "password"){
            let error = validatePassword(value);
            setLoginError(values => ({...values, "generic": "", [name]: error}));
        }
    }
    const handleRegisterInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setRegisterInputs(values => ({...values, [name]: value}));
        if(name === "email"){
            let error = validateEmail(value);
            setRegisterError(values => ({...values, "generic": "", [name]: error}));
        }
        if(name === "username"){
            let error = validateUsername(value);
            setRegisterError(values => ({...values, "generic": "", [name]: error}));
        }
        if(name === "password"){
            let error = validatePassword(value);
            setRegisterError(values => ({...values, "generic": "", [name]: error}));
        }
    }

    const handleRegister = () => {
        singleClickSound();

        let user = registerInputs;
        let error = {};

        error.email = validateEmail(user.email);
        error.username = validateUsername(user.username);
        error.password = validatePassword(user.password);
        
        if (error.email || error.username || error.password) {
            setRegisterError(error);
            return;
        }

        var request = fetch("/api/auth/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        });

        request.then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response && response.success) {
                Global.setUser(response.data);
                setShowModal(MODAL_ENUM.NONE);
                window.location.reload();
            } else if (response && !response.success) {
                setRegisterError(values => ({...values, "generic": response.message}));
            } else {
                setRegisterError(values => ({...values, "generic": 'Something went wrong...'}));
            }
        }).catch(function (error) {
            setRegisterError(values => ({...values, "generic": 'Something went wrong...'}));
        });

    };

    const handleGuestLogin = () => {
        singleClickSound();

        let user = guestInputs;
        let error = validateUsername(user.username);

        if (error) {
            setGuestError(values => ({...values, "username": error}));
            return;
        }

        var request = fetch("/api/auth/guestlogin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        });

        request.then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response && response.success) {
                Global.setUser(response.data);
                setShowModal(MODAL_ENUM.NONE);
                window.location.reload();
            } else if (response && !response.success) {
                setGuestError(values => ({...values, "generic": response.message}));
            } else {
                setGuestError(values => ({...values, "generic": 'Something went wrong...'}));
            }
        }).catch(function (error) {
            setGuestError(values => ({...values, "generic": 'Something went wrong...'}));
        });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        singleClickSound();
        let user = Object.fromEntries(( new FormData(document.getElementById('loginForm'))).entries());
        var error = {};
        error.username = validateUsername(user.username);
        error.password = validatePassword(user.password);
        error.generic = '';
        if (error.username || error.password) {
            setLoginError(error);
            return;
        }

        var request = fetch("/api/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        });

        request.then(function (response) {
            return response.json();
        }).then(function (response) {
            if (response && response.success) {
                Global.setUser(response.data);
                setShowModal(MODAL_ENUM.NONE);
                window.location.reload();
            } else if (response && !response.success) {
                setLoginError(values => ({...values, "generic": response.message}));
            } else {
                setLoginError(values => ({...values, "generic": 'Something went wrong...'}));
            }
        }).catch(function (error) {
            setLoginError(values => ({...values, "generic": 'Something went wrong...'}));
        });
    };

    const handleResetPassword = () => {
    };

    const validateEmail = (email) => {
        var error = '';
        email = email? email.trim() : '';
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            error = "Invalid email.";
        }
        return error;
    };
    const validateUsername = (username) => {
        var error = '';
        username = username.trim()
        if (username === '') {
            error = 'Username is required.';
        } else if (username.length < 3 && username.length > 12) {
            error = 'Username must be between 3 to 12 characters.';
        } else if (!/^[a-zA-Z]/.test(username)) {
            error = 'Username must start with an alphabet.';
        } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
            error = 'Username must be alphanumeric.';
        }
        return error;
    }

    const validatePassword = (password) => {

        var error = '';
        password = password.trim()
        if (password === '') {
            error = 'Password is required.';
        } else if (password.length < 4) {
            error = 'Password must be greater than 4 characters.';
        } else if (!/^[a-zA-Z0-9#?!@$%^&*-]{4,}$/.test(password)) {
            error = 'Password only allow alphs-numeric and #?!@$%^&*- special characters.';
        }
        return error;
    }

    return (
        <div>
            {/* Login Options Modal */}
            <Modal ariaHideApp={false} className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.LOGIN_CHOICE}>
                <div className='modal_header'>
                    <h5 className='modal_title'>Login With</h5>
                </div>
                <div className='modal_body login_method'>
                    <p>Choose a Login Method</p>
                    <div className="loginOptions_btnWrapper">
                        <button type="button" className="btn-success btn-secondary" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.LOGIN}> <FaUserAlt /> User Name</button>
                        <button type="button" className="btn-success btn-secondary" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.GUEST_LOGIN}> <FaUserAlt /> Guest Account</button>
                    </div>
                </div>
            </Modal>

            {/* Guest Login Modal */}
            <Modal ariaHideApp={false} className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.GUEST_LOGIN}>
                <div className='modal_header'>
                    <h5 className='modal_title'>Guest Login</h5>
                </div>
                <div className='modal_body'>
                    <form id="guestLoginForm" onSubmit={handleGuestLogin}>
                        <div className="form_group">
                            <label>Guest Username</label>
                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    value={guestInputs.username || ""} 
                                    onChange={handleGuestInput}
                                    name="username"
                                    id="guest_username"
                                    placeholder="Enter username"
                                />
                                {guestError.username && <div className="error">{guestError.username}</div>}
                                {guestError.generic && <div className="error">{guestError.generic}</div>}
                            </div>
                            <div className="cg_row">
                                <div className="cg_col">
                                    <p className="bottom_line"><small>Username can contain onlt alpha-numeric value. Ex. usvav001</small></p>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="modal_footer">
                        <div className="loginOptions_btnWrapper">
                            <button type="button" className="btn-success btn-secondary" onClick={handleGuestLogin}> Guest Login</button>
                        </div>
                    </div>
                    <p className="bottom_line">
                        Create an account ? <Link to="#" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.REGISTER}> Register </Link>
                    </p>
                    <p className="bottom_line">
                        Already have an account ? <Link to="#" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.LOGIN}> Login </Link>
                    </p>
                </div>
            </Modal>

            {/* Login Modal */}
            <Modal ariaHideApp={false} className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.LOGIN}>
                <div className='modal_header'>
                    <h5 className='modal_title'>Login</h5>
                </div>
                <div className="modal_body">
                    <form id="loginForm" onSubmit={handleLogin}>
                        <div className="form_group">
                            <label htmlFor="login_username">Username</label>
                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    value={loginInputs.username || ""} 
                                    onChange={handleLoginInput}
                                    name="username"
                                    id="login_username"
                                    placeholder="Enter username"
                                />
                                {loginError.username && <div className="error">{loginError.username}</div>}
                            </div>
                        </div>
                        <div className="form_group">
                            <label htmlFor="login_password">Password</label>
                            <div>
                                <input
                                    type="password"
                                    autoComplete="off"
                                    value={loginInputs.password || ""} 
                                    onChange={handleLoginInput}
                                    name="password"
                                    id="login_password"
                                    placeholder="Enter password"
                                />
                                {loginError.password && <div className="error">{loginError.password}</div>}
                                {loginError.generic && <div className="error">{loginError.generic}</div>}
                            </div>
                        </div>
                        <p className="bottom_line">
                            {/* <Link to="#" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.FORGOT_PASS}>Forgot Password ?</Link> */}
                        </p>
                    </form>

                    <div className="modal_footer">
                        <button type="button" className="btn-success btn-secondary" onClick={handleLogin}>Login</button>
                    </div>

                    <p className="bottom_line">
                        Create an account ? <Link to="#" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.REGISTER}> Register </Link>
                    </p>
                </div>
            </Modal>

            {/* Register Modal */}
            <Modal ariaHideApp={false} className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.REGISTER}>
                <div className='modal_header'>
                    <h5 className='modal_title'>Signup</h5>
                </div>
                <div className="modal_body">
                    <form id="registerForm"  onSubmit={handleRegister}>
                        <div className="form_group">
                            <label htmlFor="register_email">Email</label>
                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    value={registerInputs.email || ""} 
                                    onChange={handleRegisterInput}
                                    name="email"
                                    id="register_email"
                                    placeholder="Enter email"
                                />
                                {registerError.email && <div className="error">{registerError.email}</div>}
                            </div>
                        </div>
                        <div className="form_group">
                            <label htmlFor="register_username">Username</label>
                            <div>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    value={registerInputs.username || ""} 
                                    onChange={handleRegisterInput}
                                    name="username"
                                    id="register_username"
                                    placeholder="Enter username"
                                />
                                {registerError.username && <div className="error">{registerError.username}</div>}
                            </div>
                        </div>
                        <div className="form_group">
                            <label htmlFor="register_password">Password</label>
                            <div>
                                <input
                                    type="password"
                                    autoComplete="off"
                                    value={registerInputs.password || ""} 
                                    onChange={handleRegisterInput}
                                    name="password"
                                    id="register_password"
                                    placeholder="Enter password"
                                />
                                {registerError.password && <div className="error">{registerError.password}</div>}
                                {registerError.generic && <div className="error">{registerError.generic}</div>}
                            </div>
                        </div>
                    </form>
                    <div className="modal_footer">
                        <button type="button" className="btn-success btn-secondary" onClick={handleRegister}>Signup</button>
                    </div>

                    <p className="bottom_line">
                        Already have an account ?
                        <Link to="#" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.LOGIN}> Login </Link>
                        |
                        <Link to="#" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.GUEST_LOGIN}> Guest Login </Link>
                    </p>

                </div>

                <Link to="#" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
                    <span className="cg_ScoreModal_close_icon"></span>
                </Link>
            </Modal>


            {/* Reset Password Modal */}
            <Modal ariaHideApp={false} className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.RESET_PASS}>
                <div className='modal_header'>
                    <h5 className='modal_title'>Reset Password</h5>
                </div>
                <div className='modal_body login_method'>

                </div>

                <Link to="#" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
                    <span className="cg_ScoreModal_close_icon"></span>
                </Link>
            </Modal>

        </div>
    )
}

export default AuthModal;
