import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import "./Home.css";
import Modal from 'react-modal';
import { selectSoundFile, singleClickSoundFile } from "../../audio";
import Global from "../Global";
import Topbar from "./partial/Topbar";
import AuthModal from "./modals/AuthModal";


const Home = ({ onResetPassword }) => {
    const navigate = useNavigate();

    const [selectPlay] = useSound(selectSoundFile, {
        volume: Global.sound ? Global.volume : 0,
    });

    const [singleClickSound] = useSound(singleClickSoundFile, {
        volume: Global.sound ? Global.volume : 0,
    });

    const [playWithFriend, setPlayWithFriend] = useState(false);
    const [joinFriend, setJoinFriend] = useState(false);
    const [tableCode, setTableCode] = useState(null);
    const [showPlayWithComputerModal, setShowPlayWithComputerModal] = useState(false);
    const [ShowPlayTournamentModal, setShowPlayTournamentModal] = useState(false);

    const handlePlayWithFriend = (e) => {
        e.preventDefault();
        selectPlay();

        setPlayWithFriend(true);
        setJoinFriend(false);
        setTableCode(null);
        navigate(`/playwithfrnd`);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    const closePlayWithFriend = (e) => {
        e.preventDefault();
        setPlayWithFriend(false);
        setJoinFriend(false);
        setTableCode(null);
    };

    const handleCreateTable = (e) => {
        e.preventDefault();
        alert("Coming soon....");
    };

    const handleJoinTable = (e) => {
        e.preventDefault();
        alert("Coming soon....");
    };

    const handlePlayOnline = (e) => {
        e.preventDefault();
        selectPlay();

        navigate(`/gameTable?gameType=Online&tournamentId=`);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    const handlePlayWithComputer = (e) => {
        e.preventDefault();
        selectPlay();

        setShowPlayWithComputerModal(true);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    const handlePlayTournament = (e) => {
        e.preventDefault();
        selectPlay();

        setShowPlayTournamentModal(true);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    // Modals closing code
    const closeModal = () => {
        singleClickSound();

        setShowPlayWithComputerModal(false);
        setShowPlayTournamentModal(false);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    return (
        <>
            <Topbar showSelectAvatar={true} showGameHistory={true} showScoreModal={false}/>
            <div className="cg_main_container">
                <div className="cg_play_btn_wrapper">
                    <div className="cg_play_btn play_online" onClick={handlePlayOnline}>
                        <i className="icon"></i>
                        <span className="title">Play Online</span>
                    </div>
                    {/* <div className="cg_play_btn play_with_frnd" onClick={handlePlayWithFriend}>
                        <i className="icon"></i>
                        <span className="title">Play with Friends</span>
                    </div>
                    <div className="cg_play_btn play_with_computer" onClick={handlePlayWithComputer}>
                        <i className="icon"></i>
                        <span className="title">Computer</span>
                    </div>
                    <div className="cg_play_btn play_tournamnet" onClick={handlePlayTournament}>
                        <i className="icon"></i>
                        <span className="title">Tournament</span>
                    </div> */}
                </div>
            </div>

            {/* Play with Computer Modal  */}
            <Modal className="featureModal" isOpen={showPlayWithComputerModal}>
                <div className="form_group">
                    <label>Coming soon....</label>
                </div>

                <Link to="#" className="cg_ScoreModal_close" onClick={closeModal}>
                    <span className="cg_ScoreModal_close_icon"></span>
                </Link>
            </Modal>

            {/* Play Tournament Modal */}
            <Modal className="featureModal" isOpen={ShowPlayTournamentModal}>
                <div className="form_group">
                    <label>Coming soon....</label>
                </div>

                <Link to="#" className="cg_ScoreModal_close" onClick={closeModal}>
                    <span className="cg_ScoreModal_close_icon"></span>
                </Link>
            </Modal>

            <AuthModal />

        </>
    );
};

export default Home;
