import React from "react";
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import "./Home.css";
import Topbar from "./partial/Topbar";
import { selectSoundFile } from "../../audio";

import Global from "../Global";

const Playwithfrnd = () => {
    const navigate = useNavigate();

    const [selectPlay] = useSound(selectSoundFile, {
        volume: Global.sound ? Global.volume : 0,
    });

    const handleCreateTable = (e) => {
        e.preventDefault();
        
        selectPlay();

        navigate(`/createtable`);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };
    const handleJoinTable = (e) => {
        e.preventDefault();
        
        selectPlay();
        
        navigate(`/jointable`);

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([300]);
        }
    };

    return (
        <>
            <Topbar showSelectAvatar={true} showGameHistory={true}/>
            <div className="cg_main_container">
                <div className="cg_play_btn_private">
                    <div className="cg_play_btn create_table" onClick={handleCreateTable}>
                        <i className="icon"></i>
                        <span className="title">Create Table</span>
                    </div>
                    <div className="cg_play_btn join_table" onClick={handleJoinTable}>
                        <i className="icon"></i>
                        <span className="title">Join Table</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Playwithfrnd;
