import React, { useState } from "react";
import useSound from 'use-sound';
import Global from "../Global";

import { singleClickSoundFile } from "../../audio";



const Tabs = ({ tabs }) => {

    const [activeTab, setActiveTab] = useState(0);

    const [singleClickSound] = useSound(singleClickSoundFile, {
        volume: Global.sound ? Global.volume : 0,
    });

    const handleTabClick = (index) => {
        singleClickSound();

        setActiveTab(index);
    }
    return (
        <>
            <div className="tabs_container">
                <div className="tabs">
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={`tab ${index === activeTab ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}>
                            {tab.title}
                        </div>
                    ))}
                </div>

                <div className="tabs_content">
                    {tabs[activeTab].content}
                </div>
            </div>
        </>
    )
}


export default Tabs;