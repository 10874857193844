import React from "react";
import './Player.css'

const PlayerCard = (props) => {

    return (
        <>
            <div pindex={props.pIndex} className={`cg_card cg_card_${props.suit}`} value={props.seq} card={props.suit + '_' + props.seq} onClick={props.throwCardFunction}>
                <div className="cg_card_icon"></div>
                <div className="cg_card_icon_big"></div>
            </div>
        </>
    );
}

export default PlayerCard;