import React, { useState, useEffect, useContext } from 'react';
import useSound from 'use-sound';
import './GameTable.css';
import { SocketContext } from '../../context/socket';

import "react-simple-keyboard/build/css/index.css";

import PlayerContainer from './partial/PlayerContainer';
import WinnerCard from './partial/WinnerCard';

import Topbar from './partial/Topbar';
import BottomBar from './partial/Bottombar';
import MyCards from './partial/MyCards';
import ScoreModal from '../Modals/ScoreModal';

import Global from '../Global'

import { cardFlipSoundfile, singleClickSoundFile, cardPullFile, cardDropFile } from '../../audio';


const GameTable = (e) => {

	const cardSequance = { '14': 'A', '13': 'K', '12': 'Q', '11': 'J', '10': '10', '5': '5', '9': '9', '8': '8', '7': '7', '6': '6', '4': '4', '3': '3', '2': '2' };
	const cardSuits = { 'S': 'Spade', 'H': 'Heart', 'C': 'Club', 'D': 'Diamond' };
	const [user, setUser] = useState(Global.getUser());
	const [tournamentId, setTournamentId] = useState('');
	const [gameId, setGameId] = useState('');
	const [gameType, setGameType] = useState('');
	const [game, setGame] = useState({
		_id: null,
		tournamentId: null,
		startDate: null,
		endDate: null,
		players: [{
			userId: null,
			username: '',
			score: 0,
			rank: 0
		}],
		playerPerTable: 5,
		noOfDeck: 2,
		totalCards: [],
		totalCardsPoints: 300,
		totalCardsCount: 65,
		allPlayerCards: [
			['S_13', 'H_14']
		],
		allPlayerCardPoints: [15],
		powerSuit: "H", // default will be null
		partnersCard: [{
			card: "S_12",
			order: 1,
			playerIndex: -1 //-1 Means player not declared for this card
		}],
		bidderPartners: [0, 2], //Index of player
		opponentPartners: [1, 3], //Index of player
		playersHands: [{
			startPlayerIndex: 0,
			cards: ["S_13", "S_12", "S_11", "S_10", "S_9"],
			points: 15,
			winnerCardIndex: -1,
			winnerPlayerIndex: -1
		}
		],
		playerBidPoints: [150, 0, 0, 0, -1], //-1 Means player pass bid, 0 Means player did not bid yet
		highBidPoints: 150,
		bidWinnerIndex: -1, //-1 means bid winner is not decided yet
		gameStarted: 0, //0 is value for false, 1321231(timestamp) is value for true
		bidCompleted: 0, //0 is value for false, 1321231(timestamp) is value for true
		gameEnded: 0, //0 is value for false, 1321231(timestamp) is value for true
		nextPlayerIndex: 0, //This shows whoes tern it is
		updatedAt: new Date(),
		version: -1

	});

	const [gameLoaded, setGameLoaded] = useState(0);
	const [keyPlayerIndex, setKeyPlayerIndex] = useState(0);
	const [completedHand, setCompletedHand] = useState(null);
	const [currentHand, setCurrentHand] = useState(null);
	const [currentHandSuit, setCurrentHandSuit] = useState('');
	const [hasCurrentHandSuit, setHasCurrentHandSuit] = useState(''); // Possible values '', 'Y', 'N'

	const [myTeam, setMyTeam] = useState('');
	const [myPoint, setMyPoint] = useState(0);
	const [bidderTeamPoint, setBidderTeamPoint] = useState(0);
	const [opponentTeamPoint, setOpponentTeamPoint] = useState(0);
	const [otherTeamPoint, setOtherTeamPoint] = useState(0);
	const [playersPoint, setPlayersPoint] = useState([0, 0, 0, 0, 0]);
	const [myTeamPointTarget, setMyTeamPointTarget] = useState(0);
	const [selectPartner, setselectPartner] = useState();
	const [powerSuit, setPowerSuit] = useState('');
	const [partnersCard, setPartnersCard] = useState([]);
	const [partnerCardStep, setPartnerCardStep] = useState(0);
	const [scoreModalShow, setScoreModalShow] = useState(false);
	const [userId, setUserId] = useState(false);
	const [username, setUsername] = useState(false);


	const queryString = require('query-string');
	const queryParams = queryString.parse(window.location.search);

	const socket = useContext(SocketContext);

	const cardPullAudio = new Audio(cardPullFile);

	const cardPullSound = () => {
		cardPullAudio.play();
	};

	const [singleClickSound] = useSound(singleClickSoundFile, {
		volume: Global.getSound() ? Global.getVolume() : 0,
	});

	const [cardFlipsSound] = useSound(cardFlipSoundfile, {
		volume: Global.getSound() ? Global.getVolume() : 0,
	});

	const [cardDropSound] = useSound(cardDropFile, {
		volume: Global.getSound() ? Global.getVolume() : 0,
	});

	const [ClickBtnSound] = useSound(singleClickSoundFile, {
		volume: Global.getSound() ? Global.getVolume() : 0,
	});

	

	useEffect(() => {
		setInterval(function(){
			singleClickSound();
		}, 1000);
		let user = Global.getUser();
		setUser(user);
		if(!user){
			window.location.href = '/';
			return;
		}

		setTournamentId(queryParams.tournamentId);
		setGameId(queryParams.gameId);
		setGameType(queryParams.gameType);
		
		console.log('useEffect called =================');
		let connectData = {
			userId: user._id,
			tournamentId: queryParams.tournamentId || document.getElementById('tournamentId').value,
			gameId: queryParams.gameId || document.getElementById('gameId').value,
			gameType: queryParams.gameType || document.getElementById('gameType').value,
			enableLog: queryParams.enableLog,
			time: new Date()
		};

		const gameTableSocketIdInput = document.getElementById('gameTableSocketId');

		gameTableSocketIdInput.value = socket.id;
		socket.emit('connect_server', connectData, (response) => {
			console.log(response.status); // ok
		});
		socket.on('connect', function () {
			// When user reconnect socket and if socket id updated
			// Then emit connect_server event which help to rejoin all relavent chanel
			if (gameTableSocketIdInput.value !== socket.id) {
				gameTableSocketIdInput.value = socket.id;
				socket.emit('connect_server', connectData, (response) => {
					console.log(response.status);
				});
			}
		});

		function updateTournamentData(tournamentData) {
		}

		const cgTableElement = document.getElementById('cg_table');
		var winnerCards = document.querySelectorAll('.cg_winner_card');


		function updateGameData(type, gameData) {
			var game = cgTableElement.dataset.game;
			if (!game) {
				game = {};
			} else {
				game = JSON.parse(game);
			}
			console.log("Socket game_data_received============", new Date(), gameData);
			console.log("Game State Version VS Socket Game Version: " + (game ? game.version : 'NA') + " - " + (gameData ? gameData.version : 'NA'));
			if (gameData) {
				if (game._id === gameData._id && game.version >= gameData.version) {
					console.log("Old and new game are same");
					return;
				} else {
					console.log("Old and new game are different");
				}
				let tempGame = {
					...game,
					...gameData
				};

				let tempKeyPlayerIndex = keyPlayerIndex;
				for (let i = 0; i < tempGame.players.length; i++) {
					if (tempGame.players[i].userId === user._id) {
						tempKeyPlayerIndex = i;
						setKeyPlayerIndex(tempKeyPlayerIndex);
						break;
					}
				}
				let currentHand = tempGame.playersHands.length > 0 ? tempGame.playersHands[tempGame.playersHands.length - 1] : null;
				setCurrentHand(currentHand);
				if (currentHand && currentHand.cards.length === tempGame.playerPerTable) {
					setCompletedHand(currentHand);

					setTimeout(function () {
						var winnerStyle1 = document.getElementById('winner_style1');
						if (winnerStyle1) {
							winnerStyle1.remove();
						}

						winnerCards.forEach(function (winnerCard) {
							winnerCard.style.display = 'block';
							winnerCard.style.opacity = 0;
						});

						var newCSS = '<style id="winner_style">';
						var winnercardOffset = document.querySelector('.cg_winner_card').getBoundingClientRect();

						document.querySelectorAll('.cg_player_container .cg_player').forEach(function (player) {
							var cgCard = player.querySelector('.cg_card');

							// Hack to fix undefined isue
							if (cgCard) {
								var pindex = cgCard.getAttribute('pindex');
								newCSS += '.cg_winner_card .cg_card[pindex="' + pindex + '"]{';
								newCSS += 'top:' + (cgCard.getBoundingClientRect().top - winnercardOffset.top) + 'px;';
								newCSS += 'left:' + (cgCard.getBoundingClientRect().left - winnercardOffset.left) + 'px;';
								newCSS += '}';
							}

						});
						newCSS += '</style>';

						var winnerStyle = document.getElementById('winner_style');
						if (winnerStyle) {
							winnerStyle.remove();
						}

						document.head.insertAdjacentHTML('beforeend', newCSS);

						winnerCards.forEach(function (winnerCard) {
							winnerCard.style.opacity = 1;
						});

						setTimeout(function () {

							winnerCards.forEach(function (winnerCard) {
								winnerCard.classList.add('cg_collecting_cards');
							});

							cardPullSound()
							setTimeout(function () {
								console.log('currentHand', currentHand);
								var newCSS = '<style id="winner_style1">';
								var winnerPlayerOffset = document.querySelector('.cg_player_container .cg_player[pindex="' + currentHand.winnerPlayerIndex + '"]').getBoundingClientRect();
								newCSS += '.cg_winner_card.cg_collecting_cards .cg_card{';
								newCSS += 'top:' + (winnerPlayerOffset.top - winnercardOffset.top) + 'px !important;';
								newCSS += 'left:' + (winnerPlayerOffset.left - winnercardOffset.left) + 'px !important;';
								newCSS += '}';
								newCSS += '</style>';
								document.head.insertAdjacentHTML('beforeend', newCSS);
							}, 500);
							setTimeout(function () {
								winnerCards.forEach(function (winnerCard) {
									winnerCard.style.opacity = 0;
									winnerCard.style.display = 'none';
									winnerCard.classList.remove('cg_collecting_cards');
								});
							}, 1000);
						}, 500);

						setCurrentHand(null);
					}, 1000);
				}

				setCurrentHandSuit('');
				setHasCurrentHandSuit('');
				if (currentHand && currentHand.cards && currentHand.cards.length < tempGame.playerPerTable) {
					setHasCurrentHandSuit('N')
					let tempCurrentHandSuit = currentHand.cards[0].split('_')[0];
					setCurrentHandSuit(tempCurrentHandSuit);
					let playerCards = tempGame.allPlayerCards[tempKeyPlayerIndex];
					for (let i = 0; i < playerCards.length; i++) {
						if (playerCards[i].indexOf(tempCurrentHandSuit) === 0) {
							setHasCurrentHandSuit('Y');
							break;
						}
					}
				}

				if (partnersCard.length === 0) {
					var tempPartnersCard = tempGame.partnersCard;
					for (var i = 0; i < tempPartnersCard.length; i++) {
						if (!tempPartnersCard[i].card && tempPartnersCard[i].card === '') {
							tempPartnersCard[i].card = 'S_14';
							tempPartnersCard[i].cardSuit = 'S';
							tempPartnersCard[i].cardSeq = '14';
							tempPartnersCard[i].order = 1;
						} else {
							tempPartnersCard[i].cardSuit = tempPartnersCard[i].card.split('_')[0];
							tempPartnersCard[i].cardSeq = tempPartnersCard[i].card.split('_')[1];
						}
					}
					setPartnersCard(tempPartnersCard);
				}

				let tempMyPoint = 0;
				let tempBidderTeamPoint = 0;
				let tempOpponentTeamPoint = 0;
				let tempOtherTeamPoint = 0;
				if (tempGame.gameStarted && tempGame.bidCompleted) {
					let tempPlayersPoint = [];
					for (let i = 0; i < tempGame.playerPerTable; i++) {
						tempPlayersPoint.push(0);
					}
					let tempMyTeam = '';
					if (tempGame.bidderPartners.indexOf(tempKeyPlayerIndex) > -1) {
						tempMyTeam = 'BIDDER';
					} else if (tempGame.opponentPartners.indexOf(tempKeyPlayerIndex) > -1) {
						tempMyTeam = 'OPPONENT';
					}
					setMyTeam(tempMyTeam);

					for (let i = 0; i < tempGame.playersHands.length; i++) {
						let winnerPlayerIndex = tempGame.playersHands[i].winnerPlayerIndex;
						tempPlayersPoint[winnerPlayerIndex] += tempGame.playersHands[i].points;
						if (winnerPlayerIndex === tempKeyPlayerIndex) {
							tempMyPoint += tempGame.playersHands[i].points;
						}
						if (tempGame.bidderPartners.indexOf(winnerPlayerIndex) > -1) {
							tempBidderTeamPoint += tempGame.playersHands[i].points;
						} else if (tempGame.opponentPartners.indexOf(winnerPlayerIndex) > -1 && tempMyTeam === 'OPPONENT') {
							tempOpponentTeamPoint += tempGame.playersHands[i].points;
						} else {
							tempOtherTeamPoint += tempGame.playersHands[i].points;
						}
					}
					setMyPoint(tempMyPoint);
					setBidderTeamPoint(tempBidderTeamPoint);
					setOpponentTeamPoint(tempOpponentTeamPoint);
					setOtherTeamPoint(tempOtherTeamPoint);
					setPlayersPoint(tempPlayersPoint);
				}

				console.log('setGame(tempGame)');
				setGame(tempGame);
				
				cgTableElement.dataset.game = JSON.stringify(tempGame);

				window.version = gameData.version;
				if (window.idelTimeout) {
					clearTimeout(window.idelTimeout);
				}

				if (tempGame.gameStarted) {

					//If next player is me then set 14 seconds timeout
					let idelTimeoutMilliSec = 14000;

					//If next player is not me then set timeout base on logic
					if (tempGame.nextPlayerIndex !== tempKeyPlayerIndex) {
						let tempUserIndex = tempGame.nextPlayerIndex < tempGame.players.length ? tempGame.nextPlayerIndex : 0;
						let nextPlayer = tempGame.players[tempUserIndex];
						if (nextPlayer.type === "Bot" || nextPlayer.type === "Computer") {
							// idelTimeoutMilliSec = 5000; //(Math.floor(Math.random() * 5) + 1) * 1000
							idelTimeoutMilliSec = (Math.floor(Math.random() * 5) + 1) * 1000;
						} else {
							let addExtraSeconds = 0;
							if (tempKeyPlayerIndex > tempGame.nextPlayerIndex) {
								addExtraSeconds = tempKeyPlayerIndex - tempGame.nextPlayerIndex;
							} else {
								addExtraSeconds = tempGame.players.length - tempGame.nextPlayerIndex + tempKeyPlayerIndex - 1;
							}
							//If next player is me the nset 14 seconds + extra seconds of timeout
							idelTimeoutMilliSec = 14000 + (addExtraSeconds * 1000);
						}
					}

					window.runTimeoutLogic = function () {
						const cgTableElement = document.getElementById('cg_table');
						var game = cgTableElement.dataset.game;

						if (!game) return;
						game = JSON.parse(game);
						window.idel_timeout_data = {
							tournamentId: game.tournamentId,
							gameId: game._id,
							userId: user._id,
							playerIndex: keyPlayerIndex,
							gameType: game.gameType,
							lastVersion: window.version
						};

						socket.emit('idel_timeout', window.idel_timeout_data, (response) => {
							console.log(response.status); // ok
						});
					}
					window.idelTimeout = setTimeout(window.runTimeoutLogic, idelTimeoutMilliSec);
					console.log("idelTimeoutMilliSec: " + idelTimeoutMilliSec);
				}
			}
		}
		socket.on('data', data => {
			if (data.type === 'game_joined') {
				window.history.replaceState({}, "Game Table", `${window.location.href.split('?')[0]}?tournamentId=${queryParams.tournamentId ? queryParams.tournamentId : ''}&gameId=${data.game._id}&gameType=${queryParams.gameType}`);
			}
			if (data.type === 'game_joined' || data.type === 'game_data') {
				setGameId(data.game._id);
				setGameLoaded(true);
				updateGameData(data.type, data.game);
			}
		});

		socket.on('user_' + user._id, data => {
			console.log("Socket user_============", new Date());
			if (data.type === 'game_data') {
				updateGameData(data.type, data.game);
			} else if (data.type === 'tournament_data') {
				updateTournamentData(data.tournament);
			}
		});

		socket.on('error', data => {
			//setLoded(false)
			//setError(data.message);
			console.log("Socket error============", data)
		});

		return () => {
			socket.off('data');
			socket.off('user_' + user._id);
			socket.off('error');
		};

	}, []);


	const palceBid = (e) => {

		cardFlipsSound();

		let bidData = {
			gameId: gameId,
			userId: user._id,
			playerIndex: keyPlayerIndex,
			bidPoint: parseInt(e.currentTarget.value),
			lastVersion: window.version
		};
		socket.emit('place_bid', bidData, (response) => {
			console.log(response.status); // ok
		});
	};

	const updatePowerSuit = (e) => {
		singleClickSound();
		
		setPowerSuit(e.currentTarget.value);
	};
	const updatePowerSuitNext = (e) => {

		if (powerSuit && powerSuit !== '') {
			document.getElementById('choosePowerSuiteModalContent').style.display = 'none';
			document.getElementById('choosePartnerCardModalContent').style.display = 'block';

			if ('vibrate' in navigator ) {
				navigator.vibrate([500]);
			}
		}

		singleClickSound();
		
	};


	var orderCards = document.querySelectorAll('.select_partner_card_order .cg_card');
	var seqCards = document.querySelectorAll('.select_partner_card_seq .cg_card');

	const updatePartnerCardSuit = (e) => {
		singleClickSound();
		
		var tempPartnersCard = partnersCard;
		tempPartnersCard[partnerCardStep].cardSuit = e.currentTarget.value;
		setPartnersCard(tempPartnersCard);

		for (var i = 0; i < orderCards.length; i++) {
			orderCards[i].className = 'cg_card cg_half_card cg_card_' + e.currentTarget.value;
			seqCards[i].className = 'cg_card cg_half_card cg_card_' + e.currentTarget.value;
		}

		var parent = e.currentTarget.parentElement;
		var children = parent.children;
		for (var j = 0; j < children.length; j++) {
			children[j].classList.remove('active');
		}

		e.currentTarget.classList.add('active');
	};

	const updatePartnerCardSeq = (e) => {
		singleClickSound();
		
		var tempPartnersCard = partnersCard;
		tempPartnersCard[partnerCardStep].cardSeq = e.currentTarget.value;
		setPartnersCard(tempPartnersCard);

		for (var i = 0; i < orderCards.length; i++) {
			orderCards[i].setAttribute('value', e.currentTarget.value);
		}

		var parent = e.currentTarget.parentElement;
		var children = parent.children;
		for (var j = 0; j < children.length; j++) {
			children[j].classList.remove('active');
		}

		e.currentTarget.classList.add('active');
	};

	const updatePartnerCardOrder = (e) => {
		singleClickSound();
		
		var tempPartnersCard = partnersCard;
		tempPartnersCard[partnerCardStep].order = e.currentTarget.value;
		setPartnersCard(tempPartnersCard);

		var parent = e.currentTarget.parentElement;
		var children = parent.children;
		for (var j = 0; j < children.length; j++) {
			children[j].classList.remove('active');
		}

		e.currentTarget.classList.add('active');
	};

	const updatePartnerCardNext = (e) => {
		singleClickSound();
		
		var tempPartnersCard = partnersCard;

		var partnerCardSuit = document.querySelector('.select_partner_card_suit button.active').value;
		var partnerCardSeq = document.querySelector('.select_partner_card_seq button.active').value;
		var partnerCardOrder = document.querySelector('.select_partner_card_order button.active').value;

		tempPartnersCard[partnerCardStep].card = partnerCardSuit + '_' + partnerCardSeq;
		tempPartnersCard[partnerCardStep].cardSuit = partnerCardSuit;
		tempPartnersCard[partnerCardStep].cardSeq = partnerCardSeq;
		tempPartnersCard[partnerCardStep].order = parseInt(partnerCardOrder);
		tempPartnersCard[partnerCardStep].playerIndex = -1;

		setPartnersCard(tempPartnersCard);

		if (!partnerCardSuit || partnerCardSuit === '' || !partnerCardSeq || isNaN(partnerCardSeq) || !partnerCardOrder || isNaN(partnerCardOrder)) {
			return;
		}

		if (partnerCardStep + 1 === game.partnersCard.length) {
			//call socket
			let powerSuitAndPartnerData = {
				gameId: gameId,
				userId: user._id,
				playerIndex: keyPlayerIndex,
				powerSuit: powerSuit,
				partnersCard: tempPartnersCard,
				lastVersion: window.version
			};

			socket.emit('set_power_suit_and_partner', powerSuitAndPartnerData, (response) => {
				console.log(response.status); // ok
			});

		} else {
			var suitButtons = document.querySelectorAll('.select_partner_card_suit button');
			var seqButtons = document.querySelectorAll('.select_partner_card_seq button');
			var orderButtons = document.querySelectorAll('.select_partner_card_order button');
        
			for (var i = 0; i < suitButtons.length; i++) {
				suitButtons[i].classList.remove('active');
			}

			for (var j = 0; j < seqButtons.length; j++) {
				seqButtons[j].classList.remove('active');
			}

			for (var k = 0; k < orderButtons.length; k++) {
				orderButtons[k].classList.remove('active');
			}

			document.querySelector('.select_partner_card_suit button[value="' + partnersCard[partnerCardStep + 1].cardSuit + '"]').classList.add('active');
			document.querySelector('.select_partner_card_seq button[value="' + partnersCard[partnerCardStep + 1].cardSeq + '"]').classList.add('active');
			document.querySelector('.select_partner_card_order button[value="' + partnersCard[partnerCardStep + 1].order + '"]').classList.add('active');

			var cardSuit = partnersCard[partnerCardStep + 1].cardSuit;
			document.querySelector('.select_partner_card_seq .cg_card').className = 'cg_card cg_half_card cg_card_' + cardSuit;
			document.querySelector('.select_partner_card_order .cg_card').className = 'cg_card cg_half_card cg_card_' + cardSuit;


			setPartnerCardStep(partnerCardStep + 1);
		}
	};

	const updatePartnerCardBack = (e) => {
		singleClickSound();
		
		let tempPartnerCardStep = partnerCardStep;
		if (tempPartnerCardStep === 0) {
			document.getElementById('choosePartnerCardModalContent').style.display = 'none';
			document.getElementById('choosePowerSuiteModalContent').style.display = 'block';

			if ('vibrate' in navigator) {
				navigator.vibrate([500]);
			}
		} else {
			let suitButtons = document.querySelectorAll('.select_partner_card_suit button');
			suitButtons.forEach(function(button) {
				button.classList.remove('active');
			});

			let seqButtons = document.querySelectorAll('.select_partner_card_seq button');
			seqButtons.forEach(function(button) {
				button.classList.remove('active');
			});

			let orderButtons = document.querySelectorAll('.select_partner_card_order button');
			orderButtons.forEach(function(button) {
				button.classList.remove('active');
			});

			let currentStep = partnerCardStep - 1;
			document.querySelector('.select_partner_card_suit button[value="' + partnersCard[currentStep].cardSuit + '"]').classList.add('active');
			document.querySelector('.select_partner_card_seq button[value="' + partnersCard[currentStep].cardSeq + '"]').classList.add('active');
			document.querySelector('.select_partner_card_order button[value="' + partnersCard[currentStep].order + '"]').classList.add('active');

			let cardSuit = partnersCard[currentStep].cardSuit;
			document.querySelector('.select_partner_card_seq .cg_card').className = 'cg_card cg_half_card cg_card_' + cardSuit;
			document.querySelector('.select_partner_card_order .cg_card').className = 'cg_card cg_half_card cg_card_' + cardSuit;

			setPartnerCardStep(tempPartnerCardStep - 1);
		}

	};

	const throwCard = (e) => {
		let cardData = {
			gameId: gameId,
			userId: user._id,
			playerIndex: keyPlayerIndex,
			card: e.currentTarget.getAttribute('card'),
			lastVersion: window.version
		};
		if (keyPlayerIndex === game.nextPlayerIndex) {
			if (currentHand && currentHand.cards.length > 0 && currentHand.cards.length < game.playerPerTable) {
				let handSuit = currentHand.cards[0].split('_')[0];
				let cardSuit = cardData.card.split('_')[0];

				if (game.allPlayerCards[keyPlayerIndex][handSuit].length > 0 && handSuit !== cardSuit) {
					alert('Choose right card');
					return;
				}
			}

			socket.emit('throw_card', cardData, (response) => {
				console.log(response.status); // ok
			});
		}

		cardDropSound();
		
	};

	const showScoreModal = () => {
		setScoreModalShow(true);
	};

	const closeScoreModal = (e) => {

		ClickBtnSound();

		e.preventDefault();
		setScoreModalShow(false);
	};

	const restartGame = (e) => {
		ClickBtnSound();
		
		e.preventDefault();
		window.location.href = `${window.location.href.split('?')[0]}?tournamentId=${queryParams.tournamentId ? queryParams.tournamentId : ''}&gameType=${queryParams.gameType}`;

	};

	const gotoHome = (e) => {
		ClickBtnSound();
		
		e.preventDefault();
		window.location.href = `/`;
	};


	return (
		<>

			<input type='hidden' id='gameTableSocketId' />
			<input type='hidden' id='tournamentId' value={tournamentId ? tournamentId : ''} />
			<input type='hidden' id='gameId' value={gameId ? gameId : ''} />
			<input type='hidden' id='gameType' value={gameType ? gameType : ''} />

			<div className='cg_container d-flex flex-column flex-fill'>
				<div className="cg_screen">
					<Topbar showSelectAvatar={true} showScoreModal={showScoreModal} showGameHistory={false} />
					<div className="cg_table_wrapper">
						<div className={'cg_table ' + (game.gameEnded ? 'cg_table_game_ended' : '')} id="cg_table">
							<div className='cg_table_left'>
							</div>
							<div className='cg_table_center'>
							</div>
							<div className='cg_table_right'>
							</div>
							{/* ======= User 13 cards Start ======= */}
							<div className='cg_mydesk'>
								<MyCards game={game} keyPlayerIndex={keyPlayerIndex} currentHandSuit={currentHandSuit} hasCurrentHandSuit={hasCurrentHandSuit} throwCard={throwCard} />
							</div>
							{/* ======= User 13 cards End ======= */}

							{/* ======= Players Avatar Start ======= */}
							<PlayerContainer game={game}
								keyPlayerIndex={keyPlayerIndex}
								currentHand={currentHand}
								playersPoint={playersPoint}
								bidderTeamPoint={bidderTeamPoint}
								opponentTeamPoint={opponentTeamPoint} />
							{/* ======= Players Avatar End ======= */}

							{/* =========== Winner Player Cards Start =========== */}
							{completedHand ? (<>
								<WinnerCard game={game} completedHand={completedHand} keyPlayerIndex={keyPlayerIndex} />
							</>) : null}
							{/* =========== Winner Player Cards End =========== */}
						</div>
					</div>
					<BottomBar
						game={game}
						keyPlayerIndex={keyPlayerIndex}
						palceBid={palceBid}
						completedHand={completedHand}
						restartGame={restartGame}
						gotoHome={gotoHome}
						myTeam={myTeam}
						bidderTeamPoint={bidderTeamPoint}
						opponentTeamPoint={opponentTeamPoint}
						otherTeamPoint={otherTeamPoint}
					/>
				</div>
				{/* select partner card model */}
				{game.bidCompleted && (!game.powerSuit || game.powerSuit === '') && game.nextPlayerIndex === keyPlayerIndex ? (<>
					<div className='modal partner_choice_modal'>
						<div className='modal-content' id="choosePowerSuiteModalContent">
							<div className='modal_header'>
								<h5 className='secondary_text'>Select Power Suit</h5>
							</div>
							<div className='model_body'>
								<h5 className='text_white'>My Cards</h5>
								<div>
									<MyCards game={game} keyPlayerIndex={keyPlayerIndex} />
								</div>
								<div className='select_power_suit'>
									{Object.keys(cardSuits).map(function (key, index) {
										return (
											<button className={`${powerSuit === key ? 'active' : ''}`} value={key} onClick={updatePowerSuit}>
												<div className={`cg_card cg_half_card cg_card_${key}`}>
													<div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
												</div>
											</button>
										)
									})}
								</div>
								<div><h5 className='select_power_suit_error text_white'>Please choose power suit and press next</h5></div>
							</div>
							<div className='model_footer'>
								<button className='btn-success btn-secondary' onClick={updatePowerSuitNext}>Next</button>
							</div>
						</div>
						<div className='modal-content' id="choosePartnerCardModalContent" style={{ display: "none" }}>
							<div className='modal_header'>
								<h5 className='secondary_text'>Select
									{partnerCardStep === 0 ? ' First ' : (partnerCardStep === 1 ? ' Second ' : (partnerCardStep === 2 ? ' Third ' : (' ' + (partnerCardStep + 1) + 'th ')))}
									Partner Card</h5>
							</div>
							<div className='model_body'>
								<h5 className='text_white'>My Cards</h5>
								<div>
									<MyCards game={game} keyPlayerIndex={keyPlayerIndex} />
								</div>
								<div className='select_partner_card_suit'>
									{Object.keys(cardSuits).map(function (key, index) {
										return <>
											<button id={index + 1} key={index} className={`${partnersCard[partnerCardStep].cardSuit === key ? 'active' : ''}`} onClick={updatePartnerCardSuit} value={key}>
												<div className={`cg_card cg_half_card cg_card_${key}`}>
													<div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
												</div>
											</button>
										</>
									})}
								</div>
								<div className='select_partner_card_seq'>
									{Object.keys(cardSequance).slice(0).reverse().map(function (key, index) {
										return <>
											<button id={index + 1} key={index} className={`${partnersCard[partnerCardStep].cardSeq === key ? 'active' : ''}`} onClick={updatePartnerCardSeq} value={key}>
												<div className={`cg_card cg_half_card cg_card_${partnersCard[partnerCardStep].cardSuit}`} value={key}>
													<div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
												</div>
											</button>
										</>
									})}
								</div>
								<div><h5 className='text_white'>There are <span className='text_yellow'>{game.noOfDeck}</span> deck of cards please select your card</h5></div>
								<div className='select_partner_card_order'>
									{
										[...Array(game.noOfDeck)].map((value, index) => (
											<button id={index + 1} key={index} className={`${partnersCard[partnerCardStep].order === (index + 1) ? 'active' : ''}`} onClick={updatePartnerCardOrder} value={index + 1}>
												<div className={`cg_card cg_half_card cg_card_${partnersCard[partnerCardStep].cardSuit}`} value={partnersCard[partnerCardStep].cardSeq}>
													<div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
													<div className='cg_deck_order'>{index + 1}</div>
												</div>
											</button>
										))
									}

								</div>
							</div>
							<div className='model_footer'>
								<button className='btn-success btn-secondary' onClick={updatePartnerCardBack}>BACK</button>
								<button className='btn-success btn-secondary' onClick={updatePartnerCardNext}>
									{(partnerCardStep + 1) === game.partnersCard.length ? "Submit" : "Next"}
								</button>
							</div>
						</div>
					</div>
				</>) : null}

				<ScoreModal game={game} playersPoint={playersPoint}
					showModal={scoreModalShow}
					closeModal={closeScoreModal}
					keyPlayerIndex={keyPlayerIndex}
					bidderTeamPoint={bidderTeamPoint}
					opponentTeamPoint={opponentTeamPoint}
					otherTeamPoint={otherTeamPoint} />
			</div>
		</>
	)
}

export default GameTable

