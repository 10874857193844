import React from 'react';
import './Tabs.css';
import GameHistory from './GameHistoryCard';
import Tabs from './Tabs';
import Topbar from './partial/Topbar';



const GameHistoryList = () => {
    const tabData = [
        {
            title: 'Completed',
            content: <>
                <GameHistory TournamentName="Play Online" Result="Loss" ghPlayersPoint="100" />
                <GameHistory TournamentName="Play Computer" Result="Win" ghPlayersPoint="50" />
                <GameHistory TournamentName="Play Friends" Result="Loss" ghPlayersPoint="60" />
            </>,
        },
        {
            title: 'Contest',
            content: <>
                <GameHistory TournamentName="Play Online" Result="Loss" ghPlayersPoint="40" />
            </>,
        },
        {
            title: 'My Contest',
            content: <>
                <GameHistory TournamentName="Play Online" Result="Loss" ghPlayersPoint="20" />
                <GameHistory TournamentName="Play Online" Result="Win" ghPlayersPoint="120" />
            </>,
        },
    ];


    return (
        <>
            <Topbar showSelectAvatar={true} showGameHistory={false} showScoreModal={true}/>
            <div className='tabs_wrapper'>
                <Tabs tabs={tabData} />
            </div>
        </>
    );
}

export default GameHistoryList;
