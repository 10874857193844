import React from "react";
import PlayerCard from './PlayerCard';
import './Player.css'
import Lottie from "lottie-react";
import bubleAnimation from '../animation/bubble-anim.json'

const CgPlayerAvatar = (props) => {

     return (
        <>
            <div pindex={props.pIndex} style={{ animationDelay: props.animationDelay }} 
                className={`cg_player ${props.isPP === 'P' ? "cg_player_partner" : (props.isPP === 'O' ? "cg_player_opponent" : "")} ${props.isRT ? "cg_rounded_timer" : ""} ${props.positionClass} ${props.username===''?'cg_player_waiting':''} player_avatar ${props.avatar? props.avatar: ''}`}>
                <h5 className='player_info_name'>{props.username===''?'Waiting...':props.username}</h5>

                {
                    props.suit !== '' ? (<>
                        <PlayerCard pIndex={props.pIndex} suit={props.suit} seq={props.seq} />
                    </>) : null
                }
                <div className="cg_player_score">{props.playerScore}</div>
                <div className="cg_player_partner_field">{props.isPP}</div>
                <div className={`cg_player_time ${props.isShowTag ? "show" : ""}`}>{props.playerTimer}</div>
                {
                    props.isBidWinner ? (<>
                        <div className="cg_player_crown"></div>
                    </>) : null
                }
                
                {props.gameResult && props.gameResult === "winner"? (
                    <div className="bubleAnimation">
                        <Lottie animationData={bubleAnimation} />
                    </div>
                ) : null}


            </div>
        </>
    );
}

export default CgPlayerAvatar;