import React, { useEffect, useState } from "react";
import CgPlayerAvatar from './CgPlayerAvatar';
import Lottie from "lottie-react";
import winAnimation from '../animation/winning-anim.json'
import lossGameAnimation from '../animation/lossGame-anim.json'
import './Player.css'
import { gameWinnerSoundfile, gameLoserSoundfile, timerSoundFile } from '../../../audio';

import Global from "../../Global";


const PlayerContainer = (props) => {
    
    
    const selectedAvatar = Global.selectedAvatar;  // Used selectedAvatar from Global

    const gameWinnerAudio = new Audio(gameWinnerSoundfile);
    const gameLooserAudio = new Audio(gameLoserSoundfile);
    const timerAudio = new Audio(timerSoundFile);

    useEffect(() => {

        const timerInterval = setInterval(() => {
            handleTurnChange();
        }, 1000);

        if (showTimer(props.game, props.keyPlayerIndex)) {
            if ('vibrate' in navigator && Global.vibration) {
                navigator.vibrate([500]);
            }
        }

        return () => {
            clearInterval(timerInterval);
            timerAudio.pause();
            timerAudio.currentTime = 0;
        };

    }, [props.game.nextPlayerIndex, props.keyPlayerIndex]);


    const playWinnerSound = () => {
        gameWinnerAudio.play();
        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([500]);
        }
    };

    const playLoserSound = () => {
        gameLooserAudio.play();

        if ('vibrate' in navigator && Global.vibration) {
            navigator.vibrate([500]);
        }
    };


    let rightPlayer = ["bottomRight", "topRight"];
    let leftPlayer = ["bottomLeft", "topLeft"];
    let topPlayer = ["top_1", "top_2", "top_3", "top_4"];
    let lastUpdatedTime = new Date(props.game.updatedAt).getTime();
    let currentTime = new Date().getTime();
    let animationDelay = ((lastUpdatedTime - currentTime) / 1000) + "s";
    //console.log("Time Delay: ", new Date(props.game.updatedAt) , new Date(), animationDelay );

    const getPartnerType = (game, pIndex, keyPlayerIndex) => {
        let partnerType = '';
        let mySide = game.bidderPartners.indexOf(keyPlayerIndex) > -1 ? "B" : (game.opponentPartners.indexOf(keyPlayerIndex) > -1 ? "O" : "?");
        let playerSide = game.bidderPartners.indexOf(pIndex) > -1 ? "B" : (game.opponentPartners.indexOf(pIndex) > -1 ? "O" : "?");

        if (mySide === playerSide) {
            if (mySide === "?") {
                partnerType = "?";
            } else {
                partnerType = "P";
            }
        } else {
            if (mySide === "?" || playerSide === "?") {
                partnerType = playerSide;
            } else {
                partnerType = 'O';
            }
        }
        return partnerType;
    };


    const showTimer = (game, pIndex) => {
        return (game.gameStarted && !game.gameEnded && game.nextPlayerIndex === pIndex);
    };

    const playerGameResult = (game, pIndex) => {
        if (game.gameEnded) {
            if (
                (game.bidderPartners.indexOf(pIndex) > -1 && props.bidderTeamPoint >= game.highBidPoints) ||
                (game.opponentPartners.indexOf(pIndex) > -1 && props.bidderTeamPoint < game.highBidPoints)
            ) {
                return "winner";
            } else {
                return "looser";
            }
        }
        return "";
    };


    const handleTurnChange = () => {
        if (showTimer(props.game, props.keyPlayerIndex)) {
            timerAudio.play();
        } else {
            timerAudio.pause();
            timerAudio.currentTime = 0;
        }
    };


    return (
        <>
            <div className="cg_player_container cg_hide_winner_cards">
                <div className="cg_top_parent">
                    {props.game.players.length > 5 ? (
                        topPlayer.map((item, index) => {
                            if (props.game.playerPerTable > (4 + index)) {
                                return null;
                            }
                            let pIndex = props.keyPlayerIndex + (index + 3);
                            if (pIndex >= props.game.playerPerTable) {
                                pIndex = pIndex - props.game.playerPerTable;
                            }
                            let handCardIndex = -1;
                            let cardSuit = '';
                            let cardSequance = '';
                            if (props.currentHand) {
                                handCardIndex = pIndex - props.currentHand.startPlayerIndex;
                                if (handCardIndex < 0) {
                                    handCardIndex = props.game.playerPerTable + handCardIndex;
                                }
                                if (props.currentHand.cards.length > handCardIndex) {
                                    cardSuit = props.currentHand.cards[handCardIndex].split('_')[0];
                                    cardSequance = props.currentHand.cards[handCardIndex].split('_')[1];
                                }
                            }
                            if (props.game.playerPerTable > pIndex) {
                                return (
                                    <CgPlayerAvatar
                                        key={pIndex}
                                        isPP={getPartnerType(props.game, pIndex, props.keyPlayerIndex)}
                                        isRT={showTimer(props.game, pIndex)}
                                        isBidWinner={props.game.bidWinnerIndex === pIndex}
                                        isShowTag={false}
                                        cardName={cardSuit}
                                        cardValue={cardSequance}
                                        username={props.game.players.length > pIndex ? props.game.players[pIndex].username : ''}
                                        avatar={props.game.players.length > pIndex ? props.game.players[pIndex].avatar : ''}
                                        pIndex={pIndex}
                                        playerScore={props.game.players.length > pIndex ? props.playersPoint[pIndex] : 0}
                                        playerTimer="30"
                                        animationDelay={animationDelay}
                                        gameResult={playerGameResult(props.game, pIndex)}
                                    />
                                )
                            }
                            return null;
                        })) : null}
                </div>


                {leftPlayer.map((item, index) => {
                    let pIndex = props.keyPlayerIndex + (index + 1);
                    if (pIndex >= props.game.playerPerTable) {
                        pIndex = pIndex - props.game.playerPerTable;
                    }
                    let handCardIndex = -1;
                    let cardSuit = '';
                    let cardSequance = '';
                    if (props.currentHand) {
                        handCardIndex = pIndex - props.currentHand.startPlayerIndex;
                        if (handCardIndex < 0) {
                            handCardIndex = props.game.playerPerTable + handCardIndex;
                        }
                        if (props.currentHand.cards.length > handCardIndex) {
                            cardSuit = props.currentHand.cards[handCardIndex].split('_')[0];
                            cardSequance = props.currentHand.cards[handCardIndex].split('_')[1];
                        }
                    }

                    return (
                        <CgPlayerAvatar
                            key={pIndex}
                            isPP={getPartnerType(props.game, pIndex, props.keyPlayerIndex)}
                            isRT={showTimer(props.game, pIndex)}
                            isBidWinner={props.game.bidWinnerIndex === pIndex}
                            isShowTag={false}
                            suit={cardSuit}
                            seq={cardSequance}
                            positionClass={item}
                            username={props.game.players.length > pIndex ? props.game.players[pIndex].username : ''}
                            avatar={props.game.players.length > pIndex ? props.game.players[pIndex].avatar : ''}
                            pIndex={pIndex}
                            playerScore={props.game.players.length > pIndex ? props.playersPoint[pIndex] : 0}
                            playerTimer="30"
                            animationDelay={animationDelay}
                            gameResult={playerGameResult(props.game, pIndex)}
                        />
                    )
                })}

                {props.keyPlayerIndex > -1 && props.game.players.length > props.keyPlayerIndex ? (<>
                    {
                        [props.keyPlayerIndex].map(function (object, i) {
                            let handCardIndex = -1;
                            let cardSuit = '';
                            let cardSequance = '';
                            if (props.currentHand) {
                                handCardIndex = props.keyPlayerIndex - props.currentHand.startPlayerIndex;
                                if (handCardIndex < 0) {
                                    handCardIndex = props.game.playerPerTable + handCardIndex;

                                }
                                if (props.currentHand.cards.length > handCardIndex) {
                                    cardSuit = props.currentHand.cards[handCardIndex].split('_')[0];
                                    cardSequance = props.currentHand.cards[handCardIndex].split('_')[1];
                                }
                            }
                            return (
                                <CgPlayerAvatar
                                    key={props.keyPlayerIndex}
                                    isPP='P'
                                    isRT={showTimer(props.game, props.keyPlayerIndex)}
                                    isBidWinner={props.game.bidWinnerIndex === props.keyPlayerIndex}
                                    isShowTag={false}
                                    suit={cardSuit}
                                    seq={cardSequance}
                                    positionClass={`bottom ${selectedAvatar}`}
                                    username={props.game.players[props.keyPlayerIndex].username}
                                    avatar={props.game.players[props.keyPlayerIndex].avatar}
                                    pIndex={props.keyPlayerIndex}
                                    playerScore={props.playersPoint[props.keyPlayerIndex]}
                                    playerTimer="30"
                                    animationDelay={animationDelay}
                                    gameResult={playerGameResult(props.game, props.keyPlayerIndex)}
                                />
                            );
                        })
                    }
                </>) : null}

                {rightPlayer.map((item, index) => {
                    let pIndex = props.keyPlayerIndex - (index + 1);
                    if (pIndex < 0) {
                        pIndex = props.game.playerPerTable - (index + 1) + props.keyPlayerIndex;
                    }
                    let handCardIndex = -1;
                    let cardSuit = '';
                    let cardSequance = '';
                    if (props.currentHand) {
                        handCardIndex = pIndex - props.currentHand.startPlayerIndex;
                        if (handCardIndex < 0) {
                            handCardIndex = props.game.playerPerTable + handCardIndex;
                        }
                        if (props.currentHand.cards.length > handCardIndex) {
                            cardSuit = props.currentHand.cards[handCardIndex].split('_')[0];
                            cardSequance = props.currentHand.cards[handCardIndex].split('_')[1];
                        }
                    }
                    return (
                        <CgPlayerAvatar
                            key={pIndex}
                            isPP={getPartnerType(props.game, pIndex, props.keyPlayerIndex)}
                            isRT={showTimer(props.game, pIndex)}
                            isBidWinner={props.game.bidWinnerIndex === pIndex}
                            isShowTag={false}
                            suit={cardSuit}
                            seq={cardSequance}
                            positionClass={item}
                            username={props.game.players.length > pIndex ? props.game.players[pIndex].username : ''}
                            avatar={props.game.players.length > pIndex ? props.game.players[pIndex].avatar : ''}
                            pIndex={pIndex}
                            playerScore={props.game.players.length > pIndex ? props.playersPoint[pIndex] : 0}
                            playerTimer="30"
                            animationDelay={animationDelay}
                            gameResult={playerGameResult(props.game, pIndex)}
                        />
                    )
                })}

                <div>
                    {playerGameResult(props.game, props.keyPlayerIndex) ? (
                        <div className={`cg_game_result ${playerGameResult(props.game, props.keyPlayerIndex)}`}>
                            {playerGameResult(props.game, props.keyPlayerIndex) === "winner" ? (
                                <div className="animContentWrap">
                                    <div className="animationIcon">
                                        <Lottie animationData={winAnimation} />
                                        {playWinnerSound()}
                                    </div>
                                    <div className="matchMsgBox">
                                        <h3>Congratulations! You won along with (player 1, player 2, player 3) as team.</h3>
                                    </div>
                                </div>
                            ) : (
                                <div className="animContentWrap">
                                    <div className="animationIcon">
                                        <Lottie animationData={lossGameAnimation} />
                                        {playLoserSound()}
                                    </div>

                                    <div className="matchMsgBox">
                                        <h3>Oops! Better luck next time. Keep playing and strive for victory.</h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>

            </div>
        </>
    );
}

export default PlayerContainer;