import React, { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { socket, SocketContext } from './context/socket';
// Import Routes all
import { nonAuthRoutes } from "./routes/allRoutes";
import './App.css';


function App() {
  
  useEffect(() => {    
	}, []);
  
  return (
    <BrowserRouter>
        <SocketContext.Provider value={socket}>
          <Routes>
            {nonAuthRoutes.map( ( route, idx ) => (
                <Route
                  path={route.path}
                  element={route.component}
                  key={idx}
                  exact={true}
                />
              ) )
            }
          </Routes>
        </SocketContext.Provider>
    </BrowserRouter>
  );
}

export default App;