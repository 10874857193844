import React from "react";
import './TournamentCard.css';

const TournamentCard = ({ 
    showStar,
    fastMessage,
    playerInfo,
    EventTag,
    EventInfo,
    TournamentName,
    PrizeAmount,
    showButton,
    showProgress,
    WinnerPrizeAmount,
    ContestSize,
    
 }) => {
    return (
        <>
            <div className="cg_tournement_card">
                <div className="top_message">
                    {showStar && <div className="cg_star"></div>}
                    {fastMessage && <div className="cg_fast">{ EventTag }</div>}
                    {playerInfo && <div className="cg_table_player_info">{ EventInfo }</div>}
                </div>
                <div className="cg_tournement_card_header">
                    <h5 className="cg_tournement_name">
                        <img src="/image/deck/trophy-icon.svg" className="show_image" width="16" height="16" alt="Trophy Icon" />
                        {TournamentName}
                    </h5>

                    <div className="cg_tournement_info">
                        <img src="/image/deck/info-circle.svg" alt="Info Icon" />
                    </div>
                </div>

                <div className="cg_tournement_card_body">
                    <div className="inner_content">
                        <h5 className="cg_tournement_card_title">Prize Amount <span className="cg_pize_amount"> &#8377;{PrizeAmount}</span></h5>
                        {showButton && <button type="submit" className="tournement_btn">Free</button>}
                    </div>
                   {showProgress && <div className="progress"></div>}
                </div>
                <div className="cg_tournement_card_footer">
                    <div className="winner_prize">Winners: { WinnerPrizeAmount }</div>
                    <div className="contest_size">{ContestSize}</div>
                    <div className="cg_tournement_time">Start: 04:00 PM, Today</div>
                </div>
            </div>
        </>
    );
}

export default TournamentCard;
