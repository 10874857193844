import React from 'react';
import Modal from 'react-modal';
import ScorewindowTable from '../pages/modals/ScorewindowTable';

import '../Modals/ScoreModal.css';
import { Link } from 'react-router-dom';


function ScoreModal({showModal, playersPoint, game, keyPlayerIndex, closeModal, bidderTeamPoint, opponentTeamPoint, otherTeamPoint}) {

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      contentLabel="Table Modal"
      overlayClassName="overlay"
      className="content"  
    >
      <ScorewindowTable
        game={game}
        playersPoint={playersPoint}
        keyPlayerIndex={keyPlayerIndex}
        bidderTeamPoint={bidderTeamPoint}
        opponentTeamPoint={opponentTeamPoint}
        otherTeamPoint={otherTeamPoint}
        />
      
      <Link to="#" className="cg_ScoreModal_close" onClick={closeModal}>
        <span className="cg_ScoreModal_close_icon"></span>
      </Link>
    </Modal>
  );
}

export default ScoreModal;


