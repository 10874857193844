import React from "react";
import useSound from 'use-sound';
import "./Home.css";
import Topbar from "./partial/Topbar";
import { singleClickSoundFile } from "../../audio";

import Global from "../Global";

const Jointable = () => {

    const [singleClickSound] = useSound(singleClickSoundFile, {
        // volume: 0.5,
        volume: Global.sound ? Global.volume : 0,
    });

    const handleCreateTable = (e) => {
        e.preventDefault();
        alert("Coming soon....");
    };
    const handleJoinTable = (e) => {
        e.preventDefault();
        alert("Coming soon....");
    };

    const joinRoom = (e) => {
        e.preventDefault();

        singleClickSound();
    };

    return (
        <>
            <Topbar showSelectAvatar={true} showGameHistory={false} showScoreModal={true}/>
            <div className="create_join_table">
                <h1 className="text_white title_text">Awesome!</h1>
                <img src="/image/create_table_image.svg" alt="Create Table" />
                <h4 className="text_white info_text">Create Your Table</h4>
                <form className="join_code">
                    <input className="bg_white text_success text_secondary secondary_text" />
                    <button className="btn_success text_yellow text_secondary secondary_text" onClick={joinRoom}>Join</button>
                </form>
            </div>
            <div className='cg_bottom_bar'>
                <div className="bottom_bar_content text_white">
                    <h1 className=''>Tell others to use this code to join the room.</h1>
                </div>
            </div>
        </>
    );
};

export default Jointable;
