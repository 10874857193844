import React from "react";

import PlayerCard from "./PlayerCard";

const MyCards = ( {game, keyPlayerIndex, currentHandSuit, hasCurrentHandSuit, throwCard} ) => {
    return (
        <>
            <div className='cg_mycards'>
                <div className={'cg_cards_wrap cg_current_hand_suit_' + currentHandSuit + ' cg_has_current_hand_suit_' + hasCurrentHandSuit}>
                    {game.gameStarted? (
                        Object.keys(game.allPlayerCards[keyPlayerIndex]).map(function(key, i) {
                            return (
                                game.allPlayerCards[keyPlayerIndex][key].map(function(element, j) {
                                    return (
                                        <PlayerCard key={i+'_'+j} suit={key} seq={element} throwCardFunction={throwCard}/>
                                    )
                                })
                            )
                        })
                    ):null}
                </div>
            </div>
        </>
    );
}

export default MyCards;