import React from "react";

import './ScorewindowTable.css';

const ScorewindowTable = ({game, playersPoint, keyPlayerIndex, bidderTeamPoint, opponentTeamPoint, otherTeamPoint}) => {

    
	const getPartnerType = (game, pIndex, keyPlayerIndex) => {
        if(pIndex === keyPlayerIndex){
            return 'Me';
        }
        let partnerType = 'Not Declared';
        let isBiddenPartner = game.bidderPartners.indexOf(pIndex) > -1;
        let isOpponentPartners = game.opponentPartners.indexOf(pIndex) > -1;
        if((isBiddenPartner && game.bidderPartners.indexOf(keyPlayerIndex) > -1) ||
            (isOpponentPartners && game.opponentPartners.indexOf(keyPlayerIndex) > -1)){
            partnerType = 'Partner';
        } else if((isBiddenPartner && game.opponentPartners.indexOf(keyPlayerIndex) > -1) ||
            (isOpponentPartners && game.bidderPartners.indexOf(keyPlayerIndex) > -1)){
            partnerType = 'Opponent';
        }
        return partnerType;
    };

    
	const getPlayerResult = (pIndex) => {
        if(game.bidderPartners.indexOf(pIndex) > -1){
            if(bidderTeamPoint>= game.highBidPoints){
                return "winner";
            } else if(opponentTeamPoint > (game.totalCardsPoints - game.highBidPoints)){
                return "looser";
            }
        } else if(game.opponentPartners.indexOf(pIndex) > -1){
            if(bidderTeamPoint>= game.highBidPoints){
                return "looser";
            } else if(opponentTeamPoint > (game.totalCardsPoints - game.highBidPoints)){
                return "winner";
            }
        }
        return "";
    };
    
	const getGameScore = (pIndex) => {
                        
        if(game.bidderPartners.indexOf(pIndex) > -1){
            return bidderTeamPoint;
        } else if(game.opponentPartners.indexOf(pIndex) > -1){
            return opponentTeamPoint;
        } else {
            return "-";
        }
    };

    return (
        <>
            <div className="cg_scoretable_container">
                <table className="cg_score_table" cellPadding={0} cellSpacing={0} >
                    <thead className="cg_table_header">
                        <tr className="header_top text_white">
                            <th colSpan="3" className="cg_score">
                                Score Window
                            </th>
                            <th colSpan="3" className="game_id">
                                Game ID
                                <span className="bg_white text_dark">{game._id}</span>
                            </th>
                        </tr>
                        <tr className="table_head">
                            <th>Username</th>
                            <th>Result</th>
                            <th>Partner Type</th>
                            <th>Bids</th>
                            <th>Player Score</th>
                            <th>Game Score</th>
                            {/* <th>Amount Won</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {game.players.map((item, index) =>{  
                            return (
                                <tr className={getPlayerResult(index)} key={index}>
                                    <td>{item.username}</td>
                                    <td><p className="hide_text">Lost</p><img src="/image/deck/trophy-icon.svg" className="show_image" width="40" height="40" alt="Trophy Icon" /></td>
                                    <td>{getPartnerType(game, index, keyPlayerIndex)}</td>
                                    <td>{game.playerBidPoints[index]}</td>
                                    <td>{playersPoint[index]}</td>
                                    <td>{getGameScore(index)}</td>
                                    {/* <td>Loading...</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="cg_table_footer">
                            <td colSpan="6" className="cg_power_suit">
                                <h5>Power Suit</h5>
                                <div>
                                    <div className={`cg_card cg_half_card cg_card_${game.powerSuit}`}>
                                        <div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
                                    </div>
                                </div>
                            </td>
                            <td colSpan="6" className="cg_power_suit">
                                <h5>Partner</h5>
                                <div>
                                    {								
                                        game.partnersCard.map(function(partnerCard, index) {
                                            return <>
                                                <div className={`cg_card cg_half_card cg_card_${partnerCard.cardSuit}`} value={partnerCard.cardSeq}>
                                                    <div className="cg_card_icon"></div><div className="cg_card_icon_big"></div>
                                                    <div className='cg_deck_order'>{partnerCard.order}</div>
                                                </div>
                                            </>
                                        })
                                    }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
}

export default ScorewindowTable; 