const Global = { };

Global.getVolume = () => {  
    
    let volume = localStorage.getItem('volume');
    return !volume || isNaN(volume)? 0.5: parseFloat(volume);
}
Global.getSound = () => {
    let sound = localStorage.getItem('sound');
    return sound && sound!=='true'? false: true;
}
Global.getVibration = () => {
    let vibration = localStorage.getItem('vibration');
    return vibration && vibration!=='true'? false: true;
}
Global.getUser = () => {
    let user = localStorage.getItem('user');
    try{
        user = JSON.parse(user);
    } catch(e){
        user = null;
    }
    return user;
};


Global.setVolume = (volume) => {
    Global.volume = volume;
    localStorage.setItem('volume', volume);
}
Global.setSound = (sound) => {
    Global.sound = sound;
    localStorage.setItem('sound', sound);
}
Global.setVibration = (vibration) => {
    Global.vibration = vibration;
    localStorage.setItem('vibration', vibration);
}
Global.setUser = (user) => {
    Global.user = user;
    localStorage.setItem('user', JSON.stringify(user));
};



export default Global;