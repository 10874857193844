import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import { FaShareAlt, FaUserCircle, FaHistory, FaClipboardList, FaPencilAlt, FaEnvelope, FaAngleRight } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoSettingsSharp } from 'react-icons/io5';
import { BiRefresh } from 'react-icons/bi';
import { FcOk } from "react-icons/fc";
import { RiAlertFill } from "react-icons/ri";
import { $ } from 'react-jquery-plugin';

import { singleClickSoundFile } from '../../../audio';
import { socket } from '../../../context/socket';
import Global from "../../Global";


const Topbar = ({ showScoreModal, showGameHistory }) => {

	const MODAL_ENUM = {
		NONE: "",
		PROFILE: "Profile",
		EDIT_AVATAR: "Edit Avatar",
		SETTING: "Setting",
		GAME_HISOTRY: "GAME History",
		SCORE: "Score",
		SHARE: "Share",
		VERIFY_EMAIL: "Verify Email",
		UPDATE_USERNAME: "Update Username"
	};

	let navigate = useNavigate();

	const [user, setUser] = useState(Global.getUser());

	// Avatar
	const [selectedAvatar, setSelectedAvatar] = useState('');
	const [updateAvatarError, setUpdateAvatarError] = useState('');


	const [updatedUsername, setUpdatedUsername] = useState('');
	const [updateUsernameError, setUpdateUsernameError] = useState('');


	const [updatedEmail, setUpdatedEmail] = useState('');
	const [updateEmailError, setUpdateEmailError] = useState('');
	const [updateEmailVerified, setUpdateEmailVerified] = useState(false);


	const [volumeSliderVal, setVolumeSliderVal] = useState(Global.getVolume() * 100);
	const [soundEnabled, setSoundEnabled] = useState(Global.getSound());
	const [vibrationEnabled, setVibrationEnabled] = useState(Global.getVibration());

	const [showModal, setShowModal] = useState(MODAL_ENUM.NONE);

	const [singleClickSound] = useSound(singleClickSoundFile, {
		volume: Global.getSound() ? Global.getVolume() : 0,
	});


	useEffect(() => {
		if (user) {
			setUpdatedUsername(user.username);
		}
	}, []);

	// ---------- Modals close ----------
	const playSound = (soundFunction, doVibrate) => {
		soundFunction();
		// Vibrate only if it is enabled
		if (doVibrate && Global.vibrationEnabled && 'vibrate' in navigator) {
			navigator.vibrate([500]);
		}
	};

	const handleShowModalEvent = (e) => {
		playSound(singleClickSound, true);
        let modalName = $(e.currentTarget).attr('show-modal');
		setShowModal(modalName);

		if ('vibrate' in navigator && Global.vibration) {
			navigator.vibrate([300]);
		}
	};


	const handleBackClick = () => {
		playSound(singleClickSound, true);
		navigate(-1);
	};

	const hadleRefresh = () => {
		playSound(singleClickSound, true);
		if (!socket.connected) {
			socket.connect();
		}
		if ('vibrate' in navigator && vibrationEnabled) {
			navigator.vibrate([500]);
		}
	};

	const toogleSound = (event) => {
		playSound(singleClickSound, true);
		let soundEnabled = event.target.checked;
		Global.setSound(soundEnabled);
		setSoundEnabled(soundEnabled);
	};

	const toogleVibration = (event) => {
		playSound(singleClickSound, true);
		let vibrationEnabled = event.target.checked;
		Global.setVibration(vibrationEnabled);
		setVibrationEnabled(vibrationEnabled);

		// Only vibrate if the checkbox is checked (true)
		if (vibrationEnabled && 'vibrate' in navigator) {
			navigator.vibrate([500]); // Vibrate if the checkbox is checked
		} else {
			navigator.vibrate(0); // Stop vibration if the checkbox is unchecked
		}
	};

	const handleVolumeChange = (event) => {
		Global.setVolume(event.target.value / 100);
		setVolumeSliderVal(event.target.value);
	};

	const setVolumeSlider = () => {
		return { width: `${volumeSliderVal}%` };
	};


	const navigateGameHistoryList = () => {
		navigate('/gameHistoryList');
	};

	const handleUpdateAvatar = () => {
		playSound(singleClickSound, true);

		let globalUser = Global.getUser();
		globalUser.avatar = selectedAvatar;

		let updateUser = {
			_id: user._id,
			avatar: selectedAvatar
		}
		fetch("/api/user/update", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(updateUser),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response && response.success) {
					Global.setUser(response.data);
					setUser(Global.getUser());
					setShowModal(MODAL_ENUM.PROFILE);
					window.location.reload();
				} else if (response && !response.success) {
					setUpdateAvatarError(response.message);
				} else {
					setUpdateAvatarError('Something went wrong...');
				}
			})
			.catch((error) => {
				setUpdateAvatarError('Something went wrong...');
			});
	};
	const handleUsernameChange = (e) => {
		playSound(singleClickSound, true);

		let newUsername = e.target.value;
		setUpdatedUsername(newUsername);
	};

	const saveNewUsername = (e) => {
		playSound(singleClickSound, true);
		user.username = "";
		setUser(user);
		Global.setUser(user);

		handleShowModalEvent(MODAL_ENUM.PROFILE);


		playSound(singleClickSound, true);

		let globalUser = Global.getUser();
		globalUser.username = updatedUsername;

		fetch("/api/user/update", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(globalUser),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data && data.success) {
					Global.setUser(data.data);
					setUser(Global.getUser());
					setShowModal(MODAL_ENUM.PROFILE);
					window.location.reload();
				} else if (data && !data.success) {
					setUpdateUsernameError(data.message);
				} else {
					setUpdateUsernameError('Something went wrong...');
				}
			})
			.catch((error) => {
				setUpdateUsernameError('Something went wrong...');
			});

	};

	const handleAvatarSelect = (avatar) => {
		setSelectedAvatar(avatar);
	};

	const handleInput = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		console.log(name, value);
	}

	const handleVerifyEmailbtn = (e) => {
		e.preventDefault();
		playSound(singleClickSound, true);
		handleShowModalEvent(MODAL_ENUM.PROFILE);
	}
	const handleLogout = (e) => {
		e.preventDefault();
		playSound(singleClickSound, true);
		Global.setUser(null);
		window.location.href = '/';
	}

	return (
		<>
			<div className="cg_top_bar">
				<div>
					<button className='nav_btn' onClick={handleBackClick}><IoMdArrowRoundBack /></button>
					<button type='button' className='nav_btn' onClick={hadleRefresh}><BiRefresh /></button>
				</div>
				<div className='nav_item'>
					{user && <button type='button' className='nav_btn' onClick={handleShowModalEvent} show-modal={MODAL_ENUM.PROFILE}><FaUserCircle /></button>}
					<button type='button' className='nav_btn' onClick={handleShowModalEvent} show-modal={MODAL_ENUM.SETTING}><IoSettingsSharp /></button>
					{/* {user && <button type='button' className='nav_btn' onClick={handleShowModalEvent} show-modal={MODAL_ENUM.GAME_HISOTRY}><FaHistory /></button>} */}
					{showScoreModal && <button type='button' className='nav_btn' onClick={handleShowModalEvent} show-modal={MODAL_ENUM.SCORE}><FaClipboardList /></button>}
					{/* <button type='button' className='nav_btn' onClick={handleShowModalEvent} show-modal={MODAL_ENUM.SHARE}><FaShareAlt /></button> */}
				</div >
			</div >

			<Modal className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.SETTING}>
				<div className='modal_header'>
					<h5 className='secondary_text'>Settings</h5>
				</div>
				<div className="modal_body">
					<div className="modalSetting_list">
						<div className="modalSetting_listProp">
							<span className='prop_title'>Sound</span>
							<div className="switch_toggle">
								<input type="checkbox" id="switch1_toggle" className="toggle-input" onClick={toogleSound} checked={soundEnabled} />
								<label for="switch1_toggle" className={`toggle-label ${soundEnabled ? 'checked' : ''}`}></label>
							</div>
						</div>
					</div>
					<div className="modalSetting_list">
						<div className="modalSetting_listProp">
							<span className='prop_title'>Vibration</span>
							<div className="switch_toggle">
								<input type="checkbox" id="switch2_toggle" className="toggle-input" onClick={toogleVibration} checked={vibrationEnabled} />
								<label for="switch2_toggle" className={`toggle-label ${vibrationEnabled ? 'checked' : ''}`}></label>
							</div>
						</div>
					</div>
					<div className="modalSetting_list">
						<div className="modalSetting_listProp">
							<span className='prop_title'>Volume Control</span>
							<span className="volume_num">{volumeSliderVal}</span>
							<div className="right_side slider_wrap">
								<div className="slider_container">
									<span className="bar">
										<span className="fill" style={setVolumeSlider()}></span>
									</span>
									<input id="slider" className="slider" type="range" min="0" max="100" value={volumeSliderVal} onChange={handleVolumeChange} />
								</div>
							</div>
						</div>
					</div>

					{/* <div className="modalSetting_list">
						<div className="modalSetting_listProp">
							<span className='prop_title'>Upload Meme</span>
							<div className="right_side">
								<button> <FaPlus/>Add</button>
							</div>
						</div>
					</div> */}
				</div>

				<Link to="#" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
					<span className="cg_ScoreModal_close_icon"></span>
				</Link>
			</Modal>



			{/* Profile Modal */}
			<Modal className="featureModal modal_content profile_modal" isOpen={showModal === MODAL_ENUM.PROFILE}>
				<div className='modal_header'>
					<h5 className='modal_title'>Profile</h5>
				</div>
				<div className="modal_body">
					<div className="form_group">
						<div className="cg_row">
							<div className="cg_col_auto">
								<div className={`player_avatar  ${user && user.avatar? user.avatar : 'avatar-5'}`}>
									<span id="text-avatar">Choose</span>
									<div className={`avatar_edit ${selectedAvatar === 'avatar-5' && 'selected'}`} onClick={handleShowModalEvent} show-modal={MODAL_ENUM.EDIT_AVATAR}>
										<FaPencilAlt />
									</div>
								</div>
							</div>
							<div className="cg_col">
								<div class="player_profile_details">
									<p>Username: {user && user.username? user.username : 'N/A'}</p>
								</div>
								<div class="player_profile_details">
									<p>Email: {user && user.email? user.email : 'N/A'}</p>
								</div>
							</div>
						</div>
						<div className="modal_footer">
							<button type="button" className="btn-success btn-secondary" onClick={handleLogout}>Logout</button>
						</div>
					</div>
					{/* <div className="form_group">
						<div class="cg_row">
							<div class="cg_col">
								<p><FaEnvelope className='text_yellow' /> Email Address</p>
							</div>
							<div class="cg_col_auto">
								{emailVerified ? (
									<p onClick={verifiedEmail} className='verifyEmail'><FcOk className='text_yellow' /> Verified</p>
								) : (
									<p onClick={verifyEmail} className='verifyEmail'><RiAlertFill className='text_danger' /> Verify Now</p>
								)}
							</div>
						</div>
					</div>
					<div className="form_group">
						<div class="cg_row">
							<div class="cg_col">
								<p><FaHistory className='text_yellow' /> Game History</p>
							</div>
							<div class="cg_col_auto">
								<p><FaAngleRight className='text_white' onClick={navigateGameHistoryList} onMouseDown={handleScoreClick} /></p>
							</div>
						</div>
					</div> */}
				</div>

				<Link to="" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
					<span className="cg_ScoreModal_close_icon"></span>
				</Link>
			</Modal>

			{/* Select Avatar Modal */}
			<Modal className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.EDIT_AVATAR}>
				<div className='modal_header'>
					<h5 className='modal_title'>Select Your Avatar</h5>
				</div>
				<div className="modal_body">

					<div className="avatar_container">
						{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
							<div
								key={`avatar-${index}`}
								className={`player_avatar overlay avatar-${index} ${selectedAvatar === `avatar-${index}` ? 'selected' : ''
									}`}
								onClick={() => {
									handleAvatarSelect(`avatar-${index}`);
									playSound(singleClickSound, true);
								}}
							>
								<span id="text-avatar">Choose</span>
							</div>
						))}
					</div>

					<div className="modal_footer">
						<button className="btn-success btn-secondary" onClick={handleUpdateAvatar}>Apply</button>
					</div>
				</div>

				<Link to="#" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
					<span className="cg_ScoreModal_close_icon"></span>
				</Link>
			</Modal>



			{/* Email Modal */}
			<Modal className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.VERIFY_EMAIL}>
				<div className='modal_header'>
					<h5 className='modal_title'>Email Verification</h5>
				</div>
				<div className="modal_body">

					<div className="form_group">
						<label htmlFor="email">Enter your Email Address</label>
						<div>
							<input
								type="text"
								autoComplete="off"
								value=""
								onChange={handleInput}
								name="email"
								id="email"
							/>
							{updateEmailError && <div className="error">{updateEmailError.email}</div>}
						</div>
					</div>

					<div className="modal_footer">
						<button className="btn-success btn-secondary" onClick={handleVerifyEmailbtn}>Verify</button>
					</div>
				</div>

				<Link to="#" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
					<span className="cg_ScoreModal_close_icon"></span>
				</Link>
			</Modal>

			{/* Update UserName Modal */}
			<Modal className="featureModal modal_content" isOpen={showModal === MODAL_ENUM.UPDATE_USERNAME}>
				<div className='modal_header'>
					<h5 className='modal_title'>Profile</h5>
				</div>
				<div className="modal_body">

					<div className="form_group">
						<label htmlFor="newUsername">New Username</label>
						<div>
							<input
								type="text"
								autoComplete="off"
								value=""
								onChange={handleUsernameChange}
								name="newUsername"
								id="newUsername"
							/>
							{updateEmailError && <div className="error">{updateEmailError}</div>}
						</div>
					</div>

					<div className="modal_footer">
						<button className="btn-success btn-secondary" onClick={saveNewUsername}>Save</button>
					</div>
				</div>

				<Link to="#" className="cg_ScoreModal_close" onClick={handleShowModalEvent} show-modal={MODAL_ENUM.NONE}>
					<span className="cg_ScoreModal_close_icon"></span>
				</Link>
			</Modal>
		</>
	)
}

export default Topbar;